import React, { useState, useEffect, useContext } from 'react';
//import PropTypes from 'prop-types';
import SiteWrapper from "../../SiteWrapper.react";
import PbxDash from '../../components/Smartphone/PbxDash';
const MonitoreoLladasPage = () => {
  return (
    <SiteWrapper>
      <PbxDash monitoreo={true} />
    </SiteWrapper>
  );
}


export default MonitoreoLladasPage;
