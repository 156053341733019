import React, { useEffect, useState } from "react";
import Stomp from "stompjs";
import axios from "axios";
//import ActiveMq from "./ActiveMq";
let clientRef = null;
let instance = axios.create();
const username = "WN8z55WbAzF?bgy!BW5S9VB9$N#$fmUPx!fDf3=ZJft3mY3c@b";
const password = "DzuJVh%k&Msvr@=k2a?zZz#4CuZ55awXSYkVm?_a#+a@xQ!pDn";
let lastheartbeatTelefono = new Date().getTime();
let lastheartbeatOne = new Date().getTime();
let socketOpened = false;
//const url ="wss://b-c3b81277-c0d6-4396-b2c1-a6dc4c8d9b80-1.mq.us-east-1.amazonaws.com:61619";
//const user = "sistemas";
//const pass = "f4By4XqB8kYt";
let url =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url1 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url2 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
const user = "WEBSOCKET";const pass = "w3bs0k3t2024";

const usuario = JSON.parse(sessionStorage.getItem("usuario"));
let client = Stomp.client(url);
const TelefonoMensajeria = ({
  origen,
  topic,
  mensaje,
  usuario,
  actualValues,
  setNumero,
  setLlamar,
  setColgar,
  setTransfer,
  toggleMute,
  setContestar,
  setUsuarioEntrante,
  setEnLlamada,
  setShowIncommingCall,
  setShowTranferModal,
}) => {
  useEffect(() => {
    conectar();
  }, []);
  const conectar = () => {
    console.log("conectar al websocket: ");
    client = Stomp.client(url);
    client.heartbeat = {
      incoming: 20000,
      outgoing: 20000,
    };
    client.debug = () => {};
    client.reconnect_delay = 5000;
    client.connect(
      user,
      pass,
      (frame) => {
        //Conect callback
        //console.log(frame);
        if (topic && topic.length) {
          for (let t of topic) {
            client.subscribe(t, (message) => {
              if (message.body.includes("{")) {
                udate(message.body);
              }
              //console.log(message.body);
            });
          }
        }
        mensaje.subject = "REQUEST DATA";
        sendMessage(JSON.stringify(mensaje), usuario);
        heartbeat();
      },
      (err) => {
        console.log(err);
        url = (url === url1) ? url2 : url1;
        /*
        if(err.body){}{

        }*/
        //error useCallback
        if (err && err.toUpperCase().includes("LOST CONNECTION")) {
          setTimeout(() => {
            conectar();
          }, 5000);
          //alert(err);
        }
      }
    );
  }
  useEffect(() => {
    if (mensaje.subject) {
      sendMessage(JSON.stringify(mensaje), usuario);
    }
  }, [mensaje.subject]);


  const sendMessage = (msg, usuario) => {
    client.send("/topic/telefono-" + usuario, {}, msg);
  }
  const heartbeat = () => {
    if ( origen === "RECIBER") {
      if( lastheartbeatTelefono){
        let now = new Date().getTime();
        let dif = now - lastheartbeatTelefono;
        if(dif > 1000*60*1){//Si ha trancurrido mas de un min podemos cerrar el telefono
          //alert("Se ha perdido la conexion con skyone");
          //window.close();
        }
      }
      let mensajeHeartbeat = {
        id: "SENDER",
        subject: "heartbeat",
        msg: new Date().getTime(),
      };
      //console.log("telefono envia: ", mensajeHeartbeat);
      sendMessage(JSON.stringify(mensajeHeartbeat), usuario);
    }
    if ( origen === "SENDER") {
      if( lastheartbeatOne){
        let now = new Date().getTime();
        let dif = now - lastheartbeatOne;
        //console.log("lastview: "+ dif);
        if(dif > 1000*60*1){//Si ha trancurrido mas de un min podemos cerrar el telefono
          //alert("Se ha perdido la conexion con el telefono");
          /*
          window.open(
                        `${window.location.origin}/telefono/${usuario}`,
                        'telefono-' + usuario,
                        'width=500,height=500'
                      );*/
        }
      }
      let mensajeHeartbeat = {
        id: "RECIBER",
        subject: "heartbeat",
        msg: new Date().getTime(),
      };
      //console.log("one envia: ", mensajeHeartbeat);
      sendMessage(JSON.stringify(mensajeHeartbeat), usuario);
    }

    //sendMessage(JSON.stringify(msg), usuario);
    setTimeout(() => {
      heartbeat();
    }, (1000*15));
  }
  const udate = (message) => {
    message = message ? JSON.parse(message) : message;
    //console.log(message);
    //Revisar el lunas Es para ocultar form de llamada entrante de todas las pestañas cuando se preciona contestar
    if(origen === "SENDER" && message.subject === "ANSWER CALL"){
      //setUsuarioEntrante("");
      setShowIncommingCall(false);
    }
    if (mensaje.id === "RECIBER") {
      switch (message.subject) {
        case "heartbeat":
          if( origen === message.id){
            //console.log("telefono recibe: ", message);
            lastheartbeatTelefono = message.msg;
          }
          break;
        case "LOG UOUT":
          console.log("la sesion ha finalizado");
          //console.log("telefonoPageHeartbeat RECIBER ....!");
          break;
        case "ADD NUMBER":
          setNumero(message.msg.numero);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "CALL USER":
          setNumero(message.msg.numero);
          setLlamar(message.msg.llamar);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "END CALL":
          setColgar(message.msg.colgar);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "TRANSFER":
          setTransfer(message.msg.numero);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "MUTE":
          toggleMute();
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "ANSWER CALL":
          setContestar(message.msg.responder);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "IGNORE CALL":
          //setUsuarioEntrante(message.msg.ignorar);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "REQUEST DATA":
          console.log("Se ha recibido un mensaje => ", message);
          sendMessage(
            JSON.stringify({
              id: "SENDER",
              subject: "INITIALIZATION",
              msg: {
                user: actualValues.user,
                enLlamada: actualValues.enllamada,
              },
            }),
            usuario
          );
          break;
        default:
          break;
      }
    }
    if (mensaje.id === "SENDER") {
      switch (message.subject) {
        case "heartbeat":
          if( origen === message.id){
            //console.log("one recibe: ", message);
            lastheartbeatOne = message.msg;
          }
          break;
        case "INITIALIZATION":
          console.log("Se ha recibido un mensaje => ", message);
          setUsuarioEntrante(message.msg.user);
          setEnLlamada(message.msg.enLlamada);
          break;
        case "INCOMMING CALL":
          console.log("Se ha recibido un mensaje => ", message);
          setUsuarioEntrante(message.msg.contacto);
          setShowIncommingCall(message.msg.showModal);
          break;
        case "HANGING UP":
          console.log("Se ha recibido un mensaje => ", message);
          setUsuarioEntrante(message.msg.contacto);
          setEnLlamada(false);
          setShowIncommingCall(false);
          setShowTranferModal(false);
          break;
        case "CANCELED":
          console.log("Se ha recibido un mensaje => ", message);
          setUsuarioEntrante(message.msg.contacto);
          setEnLlamada(false);
          setShowIncommingCall(false);
          setShowTranferModal(false);
          break;
        case "TRANSFER COMPLETED":
          setUsuarioEntrante(message.msg.contacto);
          setEnLlamada(false);
          setShowIncommingCall(false);
          setShowTranferModal(false);
        default:
          break;
      }
    }
  }
  return <></>;
};
export default TelefonoMensajeria;
