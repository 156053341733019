import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { softphoneContext } from "../../context/softphoneContext";
import { TablePageable } from "../../Helpers";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import PbxMensageria from "../../socket/PbxMensageria";
import { timeToDateFill, timeToTimeFull } from "../../herramientas/DateFormat";
import Select from "react-select";
/*
const colas={
  6001: {
    "members":[
      {"paused":false,"location":"PJSIP/salvador","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/cesar.valencia","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/jorge.coutino","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/karen.afn","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":true,"location":"PJSIP/pamiro","state":"DEVICE_NOT_INUSE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/chema.afn","state":"DEVICE_NOT_INUSE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/david.garcia","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/daniela.afn1","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":1,"lastCall":1736838186},
      {"paused":false,"location":"PJSIP/guillermo.moreno","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0},
      {"paused":false,"location":"PJSIP/alanguba","state":"DEVICE_UNAVAILABLE","membership":"static","callsTaken":0,"lastCall":0}]
    ,"name":"6001",
    "event":"update-queue"
  }
};*/
let queueTemp= {};
let queueCallsTemp= {};
let queueParamsTemp= {};
const PbxDash = ({monitoreo}) => {
  const softphone = useContext(softphoneContext);

  const [troncales, setTroncales] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [ grupo, setGrupo ] = useState( { value: 0, label: "Seleccione una opción", data: null } );
  const [agentes, setAgentes] = useState([]);
  const [agente, setAgente ] = useState( { value: "", label: "Todos los agentes", data: null } );

  const [topics, setTopics]= useState([]);
  const [queue, setQueue]= useState(softphone.getValues().id? softphone.getValues().grupoExtension: null);
  const [queueMembers, setQueueMembers]= useState(softphone.getQueueMembers());
  const [queueCalls, setQueueCalls]= useState(softphone.getQueueCalls());
  const [queueParams, setQueueParams]= useState(softphone.getQueueParams());

  useEffect(()=>{
    //Buscar plataformas/Grupos

    crud("get", "", "", "softphone_configuracion" , "softphonePlataforma");
    queueTemp= softphone.getQueueMembers();
    //crud( "put",softphonLog,"","softphone_llamada_log/","softphone_log");
  },[]);

  useEffect(() => {
      if(troncales.length){
        crud("get", "", "", "softphone/grupos_list" , "grupos");
      }
    },[troncales]);

    useEffect(() => {
      if(grupo.value){
        //crud("get", "", "", "softphone/agentes/grupo/"+ grupo.value , "agentes");
        crud("get", "", "", `softphone/grupo_contactos/${grupo.value}`, "agentes");
      }
    },[grupo]);


  useEffect(()=>{
    //console.log(softphone.getQueueMembers());
    setQueueMembers({...softphone.getQueueMembers()});
    sessionStorage.setItem("queueMembers", JSON.stringify({...softphone.getQueueMembers()}));
  },[softphone.getQueueMembers()]);

  useEffect(()=>{
    //console.log(softphone.getQueueCalls());
    setQueueCalls({...softphone.getQueueCalls()});
    sessionStorage.setItem("queueCalls", JSON.stringify({...softphone.getQueueCalls()}));
  },[softphone.getQueueCalls()]);

  useEffect(()=>{
    setQueueParams({...softphone.getQueueParams()});
    sessionStorage.setItem("queueParams", JSON.stringify({...softphone.getQueueParams()}));
  },[softphone.getQueueParams()]);

  useEffect(()=>{
    if(grupo.value && grupo.data && grupo.data.extension){
      setTopics(["/topic/pbxSkyOne-"+grupo.data.extension]);
    }
  },[grupo]);
  const crud = ( metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "" ) => {
    return Crud_Catalogos( catalogo, "usuario", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            if( stateVar === "softphonePlataforma"){
              setTroncales(returnVal);
            }
            if( stateVar === "grupos"){
              let gruposTemp= [{ value: 0, label: "Seleccione una opción", data: null }];
              for( let g of returnVal){
                let troncalTemp= troncales.filter(t=> t.idGrupo === g.id);
                if( troncalTemp.length === 1){
                  g.extension= troncalTemp[0].extension;
                  gruposTemp.push({ value: g.id, label: g.name, data: g });
                  if( g.id === softphone.getValues().grupo){
                    setGrupo(gruposTemp[gruposTemp.length-1]);
                  }
                }
              }
              setGrupos(gruposTemp);
            }
            if( stateVar === "agentes"){
              setAgentes(returnVal);
            }
            /*
            if( stateVar === "agentes"){
              let agentesTemp= [{ value: "", label: "Todos los agentes", data: null }];
              for( let a of returnVal){
                agentesTemp.push({ value: a.id, label: a.name+" "+ a.lastname, data: a });
                if( a.id === softphone.getValues().idPbx){
                  setAgente(agentesTemp[agentesTemp.length-1]);
                }
              }
              setAgentes(agentesTemp);
            }*/
            break;
          case "post":
            if (stateVar === "softphone_log") {
            }
            break;
          default:
            break;
        }
    }).catch((err) => {
        console.log(err);
    });
  };
  const procesarMensaje = (msg) => {
    console.log("procesarMensaje", msg);
    if ( msg && msg.event) {
      switch (msg.event) {
        case "update-queue":
          if(!queueTemp[msg.name]){
            queueTemp[msg.name]= {};
          }
          queueTemp[msg.name]= msg;
          //console.log("update-queue", queueTemp);
          softphone.setQueueMembers({...queueTemp});
          break;
        case "update-member":
          //console.log("update-member", msg);
          /*
          {
                "name": "6001",
                "member": {
                    "paused": false,
                    "location": "PJSIP/pamiro",
                    "state": "DEVICE_UNAVAILABLE",
                    "membership": "static",
                    "callsTaken": 0,
                    "lastCall": 0
                },
                "event": "update-member"
            }
          */
          if(queueTemp[msg.name]){
            let membersTemp= queueTemp[msg.name].members;
            for(let i=0; i<membersTemp.length; i++){
              if(membersTemp[i].location === msg.member.location){
                membersTemp[i]= msg.member;
                queueTemp[msg.name].members= membersTemp;
              }
            }
            softphone.setQueueMembers({...queueTemp});
          }
          break;
        case "queueCall":
          console.log("queueCall", msg);
            /*
            {
              "event":"queueCall",
              "dialstatus":"RINGING",
              "date":"2025-01-16 18:05:24",
              "name":"6001",
              "member":"suriel",
              "callerId":"527621004239",
              "exten":"525541603232"
            }

            */
            if(!queueCallsTemp[msg.name]){
              queueCallsTemp[msg.name]= [];
            }
            //Insertamos al principio
            if(["BEGIN","RINGING", "BUSY", "CONGESTION", "NOANSWER", "CHANUNAVAIL", "ABANDONED"].includes(msg.dialstatus)){
              queueCallsTemp[msg.name].unshift(msg);
              if( queueCallsTemp[msg.name].length > 1){
                for(let i=1; i<queueCallsTemp[msg.name].length; i++){
                  if(queueCallsTemp[msg.name][i].callerId === msg.callerId){
                    queueCallsTemp[msg.name].splice(i, 1);
                  }
                }
              }
            }else{
              for(let i=0; i<queueCallsTemp[msg.name].length; i++){
                if(queueCallsTemp[msg.name][i].uniqueid === msg.uniqueid){
                  queueCallsTemp[msg.name][i].date= msg.date;
                  queueCallsTemp[msg.name][i].dialstatus= msg.dialstatus;
                }
              }

            }
            //verificamos si el elemento ya existe con anteriorida y lo borramos


           console.log(queueCallsTemp);
            softphone.setQueueCalls({...queueCallsTemp});
            break;
          case "queueParams":
            console.log("queueParams", msg);
              /*
                {
                    "date": "2025-01-21 13:42:16",
                    "talkTime": 0,
                    "calls": 0,
                    "name": "6001",
                    "completed": 0,
                    "event": "queueParams",
                    "abandoned": 0,
                    "strategy": "fewestcalls",
                    "holdTime": 0
                }
              */
              if(!queueParamsTemp[msg.name]){
                queueParamsTemp[msg.name]= {};
              }
              //Insertamos al principio
              queueParamsTemp[msg.name]= queueParamsTemp[msg.name]= msg;

              softphone.setQueueParams({...queueParamsTemp});
              break;
        default:
          break;
      }
    }
  };
  return (
    <div>{console.log(topics)}
      <PbxMensageria topics={topics} obtenerMensaje={procesarMensaje}/>
      { !monitoreo?
        <div>
          <div className="d-flex flex-row justify-content-around">
              <div>
                <div>
                  <div className="row btn">
                    <div className="col">
                      <i className="fa fa-gear text-dark" tyle={{ fontSize: "36px"}}></i>
                    </div>
                  </div>
                  <div className="row">
                    <p>Queue {queueMembers[queue]? queueMembers[queue].name: queue}</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col">
                      <i className="fa fa-users fa-2x mb-2 text-primary"></i>
                    </div>
                    <div className="col">
                      <div className="row">
                        <h2 className="card-text">{queueMembers[queue]?.members.length}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p>Total</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        queueMembers[queue]?
                          queueMembers[queue].members.filter(m=> m.state.toUpperCase() === "DEVICE_NOT_INUSE" && !m.paused)
                        .map(m=> m.location).join("\n")
                        :""
                      }
                    >
                      <i className="fa fa-users fa-2x mb-2 text-success"></i>
                    </div>
                    <div className="col">
                      <div className="row">
                        <h2 className="card-text">{queueMembers[queue]?.members.filter(m=> m.state.toUpperCase() === "DEVICE_NOT_INUSE" && !m.paused).length}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p>Disponibles </p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        queueMembers[queue]?
                        queueMembers[queue].members.filter(m=> m.state.toUpperCase() === "DEVICE_UNAVAILABLE" && !m.paused)
                        .map(m=> m.location).join("\n")
                        :""
                      }
                    >
                      <i className="fa fa-users fa-2x mb-2 text-danger"></i>
                    </div>
                    <div className="col">
                      <div className="row">
                        <h2 className="card-text">{queueMembers[queue]?.members.filter(m=> m.state.toUpperCase() === "DEVICE_UNAVAILABLE" && !m.paused).length}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p>No Disponibles </p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        queueMembers[queue]?
                        queueMembers[queue].members.filter(m=> m.paused)
                        .map(m=> m.location).join("\n")
                        :""
                      }
                    >
                      <i className="fa fa-user-times fa-2x mb-2 text-warning"></i>
                    </div>
                    <div className="col">
                      <div className="row">
                        <h2 className="card-text">{queueMembers[queue]?.members.filter(m=> m.paused).length}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p>En pausa </p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        queueMembers[queue]?
                        queueMembers[queue].members.filter(m=>
                          (!["DEVICE_NOT_INUSE","DEVICE_UNAVAILABLE"].includes(m.state.toUpperCase())))
                        .map(m=> m.location).join("\n")
                        :""
                      }
                    >
                      <i className="fa fa-feed fa-2x mb-2 text-warning"></i>
                    </div>
                    <div className="col">
                      <div className="row">
                        <h2 className="card-text">{
                          queueMembers[queue]?.members.filter(m=>
                            (!["DEVICE_NOT_INUSE","DEVICE_UNAVAILABLE"].includes(m.state.toUpperCase()))).length}
                          </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p>Ocupados </p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        queueCalls[queue]?
                        queueCalls[queue].map(c=> c.callerId+"("+c.member+")"+timeToTimeFull(c.date)).join("\n")
                        :""
                      }
                    >
                      <i className="fa fa-list fa-2x mb-2 text-warning"></i>
                    </div>
                    <div className="col">
                      <div className="row">
                        <ul>
                          { queueCalls[queue]?
                            queueCalls[queue].slice(0, 2).map((e, i) =>
                              <li key={i}>
                                <small>{e.callerId}
                                  <small className="text-muted">{"("+e.member+")"+timeToTimeFull(e.date)}</small>{/**  +"["+e.dialstatus+"]" */}
                                </small>
                              </li>
                            ): null
                          }
                        </ul>
                        {/*}
                        <ul>
                          <li>
                            <small>
                              <h6 className="mt-0">
                                527621004239
                                <small class="text-muted">pamiro.sotova.acv</small>
                              </h6>
                            </small>
                          </li>
                          <li>
                            <small>
                              <h6 className="mt-0">
                                527621004239
                                <small class="text-muted">pamiro.sotova.acv</small>
                              </h6>
                            </small>
                          </li>
                          <li>
                            <small>
                              <h6 className="mt-0">
                                527621004239
                                <small class="text-muted">pamiro.sotova.acv</small>
                              </h6>
                            </small>
                          </li>
                        </ul>*/}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p> </p>
                  </div>
                </div>
              </div>
          </div>
        </div>
       :null
      }
      { monitoreo?
        <div>
          <div className="row">
            <div className='col-3'>
              <label>Grupos</label>
              <Select value={grupo} onChange={ (e)=> setGrupo(e) } options={grupos} />
            </div>
            <div className='col-1'>
              <div className="card">
                <div className="card-body p-3 justify-content-center">
                  <h3 className="text-center">{grupo.data && queueParams[grupo.data.extension]? queueParams[grupo.data.extension].calls: "..." }</h3>
                  <p className="text-center">Llamadas</p>
                </div>
              </div>
            </div>
            <div className='col-1'>
              <div className="card">
                <div className="card-body p-3 justify-content-center">
                  <h3 className="text-center">{grupo.data && queueParams[grupo.data.extension]? queueParams[grupo.data.extension].completed: "..." }</h3>
                  <p className="text-center">Contestadas</p>
                </div>
              </div>
            </div>
            <div className='col-1'>
              <div className="card">
                <div className="card-body p-3 justify-content-center">
                  <h3 className="text-center">{grupo.data && queueParams[grupo.data.extension]? queueParams[grupo.data.extension].abandoned: "..." }</h3>
                  <p className="text-center">Perdidas</p>
                </div>
              </div>
            </div>
            <div className='col-1'>
              <div className="card">
                <div className="card-body p-3 justify-content-center">
                  <h3 className="text-center">{grupo.data && queueParams[grupo.data.extension]? queueParams[grupo.data.extension].holdTime: "..." } Seg</h3>
                  <p className="text-center">Tiempo Espera</p>
                </div>
              </div>
            </div>
            <div className='col-1'>
              <div className="card">
                <div className="card-body p-3 justify-content-center">
                  <h3 className="text-center">{grupo.data && queueParams[grupo.data.extension]? queueParams[grupo.data.extension].talkTime: "..." } S</h3>
                  <p className="text-center">Tiempo llamada</p>
                </div>
              </div>
            </div>
            <div className='col-1'>
              <div className="card">
                <div className="card-body p-3 justify-content-center">
                  <h3 className="text-center">{grupo.data && queueParams[grupo.data.extension]? queueParams[grupo.data.extension].strategy: "..." }</h3>
                  <p className="text-center">Estrategia</p>
                </div>
              </div>
            </div>
          </div>
          {/*
              {
                "id": 16051,
                "grupo": 1,
                "extension": "2005",
                "username": "alanguba",
                "descripcion": "alanguba_0698@live.com",
                "estatus": true,
                "persona": {
                    "id": 16051,
                    "nombre": "Alan",
                    "aPaterno": "Gutiérrez",
                    "aMaterno": "Bañuelos"
                }
            }
            */}{/*}
          <div className= "row" style={{height: "100px", overflowY: "scroll"}}>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
            Este es un texto con scroll vertical. <br/>
          </div>*/}
          <div className="row mt-8">
            <div className="col">
              <div className="row" style={{height: "40vh"}}>
                {/*
                  {
                    "event":"queueCall",
                    "dialstatus":"RINGING",
                    "date":"2025-01-16 18:05:24",
                    "name":"6001",
                    "member":"suriel",
                    "callerId":"527621004239",
                    "exten":"525541603232"
                  }

                */}
                {/**
                 *  BEGIN: Indica que el proceso de marcado ha comenzado.
                    RINGING: La parte remota está sonando.
                    UP: La llamada ha sido contestada (establecida).
                    BUSY: La parte remota está ocupada.
                    CONGESTION: Hay congestión en la red o en el destino.
                    NOANSWER: Nadie contestó la llamada dentro del tiempo de espera.
                    CANCEL: La llamada fue cancelada antes de ser contestada.
                    TORTURE: (Menos común) Se utiliza para indicar que se está aplicando un tratamiento especial a la llamada, como música en espera prolongada.
                    CHANUNAVAIL: El canal no está disponible.
                 */}
                <TablePageable
                  titulo={ <h3>LLamadas en espera</h3> }
                  lista= { grupo.data && queueCalls[grupo.data.extension] ?
                    queueCalls[grupo.data.extension].filter(
                      q=> ["BEGIN","RINGING", "BUSY", "CONGESTION", "NOANSWER", "CHANUNAVAIL", "ABANDONED"]
                        .includes(q.dialstatus) && (new Date().getTime()-new Date(q.date).getTime())<(1000*20))
                    :[]
                  }
                  columnas={
                    [
                      { columna: "callerId", label: "Numero" },
                      { columna: "dialstatus", label: "Estatus" },
                      { columna: "member", label: "Agente" },
                      { columna: "date",
                        label: "Fecha",
                        valor: (e)=> timeToTimeFull(e.date),
                        form: (e)=> timeToTimeFull(e.date),
                      },
                    ]
                  }
                  id= "callerId"
                  //selected={(e)=>{ setFormulario(e) }}
                  //csvname= {"Movmientos relacionados"}
                  //resaltarFuncion={(e)=> incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false}
                  //resaltarColor={"rgba(58, 223, 149, 0.25)"}
                  //paginacion={true}
                  elementosPorPagina={15}
                />
              </div>
              <div className="row mt-8" style={{height: "40vh"}}>
                <TablePageable
                  titulo={ <h3>LLamadas activas</h3> }
                  lista= { grupo.data && queueCalls[grupo.data.extension] ?
                    queueCalls[grupo.data.extension].filter(
                      q=> ["UP"]
                        .includes(q.dialstatus.toUpperCase()))
                    :[]
                  }
                  columnas={
                    [
                      { columna: "callerId", label: "Numero" },
                      { columna: "dialstatus", label: "Estatus" },
                      { columna: "member", label: "Agente" },
                      { columna: "date",
                        label: "Fecha",
                        valor: (e)=> timeToTimeFull(e.date),
                        form: (e)=> timeToTimeFull(e.date),
                      },
                    ]
                  }
                  id= "callerId"
                  //selected={(e)=>{ setFormulario(e) }}
                  //csvname= {"Movmientos relacionados"}
                  //resaltarFuncion={(e)=> incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false}
                  //resaltarColor={"rgba(58, 223, 149, 0.25)"}
                  //paginacion={true}
                  elementosPorPagina={15}
                />
              </div>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-3 container'style={{height: "80vh"}}>
              <TablePageable
                titulo={ <h3>Agentes</h3> }
                lista= { agentes.filter(a=> a.grupo === grupo.value)}
                columnas={
                  [
                    { columna: "username", label: "Agente" },
                    { columna: "state",
                      label: "Estatus",
                      valor: (e)=>{
                        let texto="";
                        if(queueMembers[grupo.data.extension] && queueMembers[grupo.data.extension].members.length){
                          let memberTem=  queueMembers[grupo.data.extension].members.filter(m=> m.location === "PJSIP/"+e.username);
                          if(memberTem.length){
                            switch (memberTem[0].state) {
                              case "DEVICE_NOT_INUSE":
                                texto= "Disponible";
                                break;
                              case "DEVICE_UNAVAILABLE":
                                texto= "No Disponible";
                                break;
                              default:
                                texto= memberTem[0].state;
                                break;
                            }
                          }else{
                            return "Desconectado";
                          }
                        }
                        return texto;
                      },
                      form: (e)=>{ //console.log(grupo,queueMembers[[grupo.data.extension]]);
                        if(queueMembers[grupo.data.extension] && queueMembers[grupo.data.extension].members.length){
                          let memberTem=  queueMembers[grupo.data.extension].members.filter(m=> m.location === "PJSIP/"+e.username);
                          if(memberTem.length){
                            let icono= {icono: "", color: "", text: "", pausado: false};
                            if(memberTem[0].paused){
                              icono.icono= "user";
                              icono.pausado= true;
                              icono.text= "Pausado";
                            }

                            switch (memberTem[0].state) {
                              case "DEVICE_NOT_INUSE":
                                icono.icono= "user";
                                icono.color= "green";
                                icono.text= "Disponible";
                                break;
                              case "DEVICE_UNAVAILABLE":
                                icono.icono= "user";
                                icono.color= "red";
                                icono.text= "No Disponible";
                                break;
                              default:
                                icono.icono= "user";
                                icono.color= "blue";
                                icono.text= memberTem[0].state;
                                break;
                            }
                            if(icono.pausado){
                              icono.color= "yellow";
                            }
                            return <i
                              className={"fa fa-"+icono.icono}
                              style={{color: icono.color}}
                            >
                              {icono.text+(icono.pausado? "(Pausado)": "")}
                            </i>;
                          }else{
                            return "Desconectado";
                          }
                        }
                        return "...";
                      }

                    },
                  ]
                }
                id= "id"
                //selected={(e)=>{ setFormulario(e) }}
                //csvname= {"Movmientos relacionados"}
                //resaltarFuncion={(e)=> incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false}
                //resaltarColor={"rgba(58, 223, 149, 0.25)"}
                //paginacion={true}
                elementosPorPagina={15}
              />
            </div>
          </div>

        </div>
        :null
      }
    </div>
  );
};

export default PbxDash;
