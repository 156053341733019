import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../herramientas/Crud_Catalogos";

/**  Date tools  */
import { timeToDateFill } from "../../../herramientas/DateFormat";
//import { from } from "rxjs";
var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
const datetime = "yyyy-mm-dd HH:mm:ss";
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}

function dateToStringFormat(date1, date2, date3) {
  var stringformat = "";
  var date = "";
  if (date1 != null) {
    date = date2;
  } else {
    date = date3;
  }

  if (date != "" && date instanceof Date) {
    var dd = date.getDate();
    var mm = date.getMonth();
    var mmm = mm + 1;
    var hora = date.getHours();
    var min = date.getMinutes();
    var seg = date.getSeconds();
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mmm < 10) {
      mmm = "0" + mmm;
    }
    stringformat =
      yyyy + "-" + mmm + "-" + dd + " " + hora + ":" + min + ":" + seg;
  }
  return stringformat;
}

class MonitoreoTerrestreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /* Llave Primaria */
      id: 0,
      accion: this.props.accion || "nuevo",
      /* Llaves Foraneas */
      operacionTipo: { value: "id", label: "Selecciona un Tipo Operacion" },
      operacionEstatus: {
        value: "id",
        label: "Selecciona una Operacion Estatus",
      },
      corredor: { value: "id", label: "Selecciona un Corredor" },
      plataforma: { value: "id", label: "Selecciona una Plataforma" },
      transportista: { value: "id", label: "Selecciona un Transportista" },
      operador: { value: "id", label: "Selecciona un Operador" },
      movimientoTipo: { value: 1, label: "Importacion" },
      movimientoTipos: [],
      modalidad: { value: 0, label: "Selecciona una modalidad" },
      modalidades: [],
      origenCatalogo: { value: 0, label: "Selecciona un origen" },
      origenesCatalogo: [],
      origenCatalogoSuburbia: { value: 0, label: "Selecciona un origen" },
      origenesCatalogoSuburbia: [],

      grupos: [],
      grupo: { value: null, label: "Seleccione una opción" },
      grupoStr: "",
      /* Campos */
      cartaPorte: "",
      rutaInicio: "2019-01-01 00:00:00",
      origen: "",
      caja: "",
      economico: "",
      ruta: "",
      nota: "",
      remolque1: "",
      remolque2: "",
      deRegreso: 0,
      custodia: 0,
      checklist: 0,
      full: null,
      actualizaFull: false,
      custodiaNombre: "",
      nombreOperador: "",
      comboOperadorEdited: false,
      transportistas: [],
      operadores: [],
      mbRutaTransporte: "",
      mbTipoProveedor: "",
      mbReferenciaAuxiliar: "",
      fechaInActCarga: "",
      fechaInActTransp: "",
      variables: [],
      movFull: {}
    };
    this.enableSave = this.enableSave.bind(this);
    this.MovimientosObject = {
      /* Llave Primaria */
      /* Llaves Foraneas */
      operacionTipo: { id: 0, nombre: "" },
      operacionEstatus: { id: 1, nombre: "SIN GPS" },
      corredor: { id: 0, nombre: "" },
      operador: { id: 1, nombre: "OPERADOR SIN CATALOGO" },
      plataforma: { id: 0, nombre: "" },
      transportista: { id: 0, nombre: "" },
      movimientoTipo: { id: 1, nombre: "Importacion" },
      /* Campos */
      cartaPorte: "",
      custodiaNombre: "",
      nombreOperador: "",
      rutaInicio: null,
      origen: "",
      nota: "",
      remolque1: "",
      remolque2: "",
      deRegreso: 0,
      custodia: 0,
      checklist: 0,
      ruta: "",
      base: "",
      gerente: "",
      grupo: "",
      caja: "",
      economico: "",
      estatusRuta: null,
      mbRutaTransporte: "",
      mbTipoProveedor: "",
      mbReferenciaAuxiliar: "",
      fechaInActCarga: null,
      fechaInActTransp: null,
      modalidad: null,
      origenCatalogoMabe: null,
      origenCatalogoSuburbia: { value: 0, label: "Seleccione" },
      full: null,
      actualizaFull: false,
    };
    this.plataforma = [];
    this.operacionTipo = [];
    this.corredor = [];
    this.operacionEstatus = [];
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: 2,
    };
  }

  cargaOrigenes() {
    Crud_Catalogos(
      "origen",
      "",
      "get",
      "",
      [],
      "",
      "",
      "",
      []
    )
      .then((returnVal) => {
        let origenCatalogoSuburbiaT = [];
        origenCatalogoSuburbiaT.push({
          value: 0,
          label: "Elija el origen" ,
        });


        returnVal.map((orig, index) => {
          origenCatalogoSuburbiaT.push({
            value: orig.id,
            label: orig.nombre,
          });
        });

        //alert(origenCatalogoSuburbiaT[1].nombre);

        this.setState({ origenCatalogoSuburbia: origenCatalogoSuburbiaT });
        //alert(this.state.origenCatalogoSuburbia.length);


      })
      .catch((err) => {
        console.log(
          "Error al Cargar origenes suburbia" +
            this.state.catalogo +
            " verifique el Nombre del Catalogo" +
            err.response
        );
      });
    /* Plataformas */
  }


  cargaIdTransportistas(idPlataforma, nombre) {
    Crud_Catalogos(
      "transportistas/plataforma/" + idPlataforma,
      "",
      "get",
      "",
      [],
      "",
      "",
      "",
      []
    )
      .then((returnVal) => {
        let transportistasT = [];
        transportistasT.push({
          value: 0,
          label: "Elija el Transportista de " + nombre,
        });
        returnVal.map((transportista, index) => {
          transportistasT.push({
            value: transportista.id,
            label: transportista.nombre,
            data: transportista,
          });
        });
        this.setState({ transportistas: transportistasT });
        //this.setState({ transportista:{ value:0, label:"Elija Transportistas de "+nombre } }); //returnVal
      })
      .catch((err) => {
        console.log(
          "Error al Cargar el Catalogo:" +
            this.state.catalogo +
            " verifique el Nombre del Catalogo" +
            err.response
        );
      });
    /* Plataformas */
  }
  cargaIdCorredores(idPlataforma, nombre) {
    Crud_Catalogos(
      "corredores/plataforma/" + idPlataforma,
      "",
      "get",
      "",
      [],
      "",
      "",
      "",
      []
    )
      .then((returnVal) => {
        this.corredor = [];
        this.corredor.push({
          value: 0,
          label: "Elija el Corredor de " + nombre,
        });
        returnVal.map((corredor, index) => {
          this.corredor.push({ value: corredor.id, label: corredor.nombre });
        });
        this.setState({
          corredor: { value: 0, label: "Elija Corredor de " + nombre },
        }); //returnVal
      })
      .catch((err) => {
        console.log(
          "Error al Cargar el Catalogo:" +
            this.state.catalogo +
            " verifique el Nombre del Catalogo" +
            err.response
        );
      });
    /* Plataformas */
  }

  cargaIdOperadores(idTransportista, nombre) {
    Crud_Catalogos(
      "operadores/transportista/" + idTransportista,
      "",
      "get",
      "",
      [],
      "",
      "",
      "",
      []
    )
      .then((returnVal) => {
        let operadoresT = [];
        operadoresT.push({ value: 0, label: "Elija Operador de " + nombre });
        operadoresT.push({ value: 1, label: "-1 OPERADOR SIN CATALOGO" });
        returnVal.map((operador, index) => {
          operadoresT.push({
            value: operador.id,
            label: operador.noEmpleado
              ? `[${operador.noEmpleado}] - ${operador.nombre} ${operador.paterno} ${operador.materno}`
              : `${operador.nombre} ${operador.paterno} ${operador.materno}`,
          });
        });
        this.setState({ operadores: operadoresT });

        //this.setState({ operador: { value:1, label:"-1 OPERADOR SIN CATALOGO" } } );
      })
      .catch((err) => {
        console.log(err);
      });
    /* Plataformas */
  }
  cargarOperacionestipo(idPlataforma, nombre) {
    // agregado el 25/05/2021  USMS

    Crud_Catalogos(
      "operacionesTipo/plataforma/" + idPlataforma,
      "",
      "get",
      "",
      [],
      "",
      "",
      "",
      []
    )
      .then((returnVal) => {
        this.operacionTipo = [];
        this.operacionTipo.push({ value: 0, label: "Elija la operacion tipo" });
        returnVal.map((operacionTipo, index) => {
          this.operacionTipo.push({
            value: operacionTipo.id,
            label: operacionTipo.nombre,
          });
        });
        // this.setState({ operacionTipo: { value: 1, label: "Elija la operacion tipo" } });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentDidMount() {
    this.getfuntion("get", "", "", "modalidades", "modalidades");
    this.getfuntion("get", "", "", "origenCatalogoMabe", "origenesCatalogo");
    this.getfuntion("get", "", "", "origen", "origenCatalogoSuburbia");
    this.cargaOrigenes();
    if (this.props.elementos && this.props.elementos.origenCatalogoSuburbia) {
      this.setState({
        origenCatalogoSuburbia: {
          value: this.props.elementos.origenCatalogoSuburbia.id,
          label: this.props.elementos.origenCatalogoSuburbia.nombre,
        },
      });
    }
    //alert(this.origenCatalogoMabe);
    if (this.state.accion !== "nuevo") {
      this.getfuntion(
        "get",
        "",
        "",
        "variables/" + this.props.elementos.plataforma.id,
        "variables"
      );
      if(this.props.elementos.full){
        this.getfuntion(
          "get",
          "",
          "",
          "terrestreMovimientos/" + this.props.elementos.full,
          "movFull"
        );
      }
      this.setState({ id: this.props.elementos.id });
      /* Combos */
      this.setState({
        plataforma: {
          value: this.props.elementos.plataforma.id,
          label: this.props.elementos.plataforma.nombre,
          data: this.props.elementos.plataforma,
        },
      });
      this.setState({
        transportista: {
          value: this.props.elementos.transportista.id,
          label: this.props.elementos.transportista.nombre,
        },
      });
      this.setState({
        operacionTipo: {
          value: this.props.elementos.operacionTipo.id,
          label: this.props.elementos.operacionTipo.nombre,
        },
      });
      this.setState({
        operacionEstatus: {
          value: this.props.elementos.operacionEstatus.id,
          label: this.props.elementos.operacionEstatus.nombre,
        },
      });
      this.setState({
        corredor: {
          value: this.props.elementos.corredor.id,
          label: this.props.elementos.corredor.nombre,
        },
      });
      if (this.props.elementos.modalidad) {
        this.setState({
          modalidad: {
            value: this.props.elementos.modalidad.id,
            label: this.props.elementos.modalidad.nombre,
          },
        });
      }
      if (this.props.elementos.origenCatalogoMabe) {
        this.setState({
          origenCatalogo: {
            value: this.props.elementos.origenCatalogoMabe.id,
            label: this.props.elementos.origenCatalogoMabe.nombre,
          },
        });
      }

      this.setState({
        operador: {
          value: this.props.elementos.operador.id,
          label: this.props.elementos.operador.nombre,
        },
      });
      this.setState({
        movimientoTipo: {
          value: this.props.elementos.movimientoTipo.id,
          label: this.props.elementos.movimientoTipo.nombre,
        },
      });
      this.getfuntion(
        "get",
        "",
        "",
        "movimientosTipo/plataforma/" + this.props.elementos.plataforma.id,
        "movimientosTipo"
      );
      if (this.props.elementos.plataforma.id === 193) {
        //193 Es traxion
        this.getfuntion("get", "", "", "traxion_grupos", "traxion_grupos");
      }
      /* Campos */
      this.setState({ cartaPorte: this.props.elementos.cartaPorte });
      this.setState({ rutaInicio: this.props.elementos.rutaInicio });
      this.setState({ origen: this.props.elementos.origen });
      this.setState({ ruta: this.props.elementos.ruta });
      this.setState({ nota: this.props.elementos.nota });
      this.setState({ remolque1: this.props.elementos.remolque1 });
      this.setState({ remolque2: this.props.elementos.remolque2 });
      this.setState({ deRegreso: this.props.elementos.deRegreso });
      this.setState({ custodia: this.props.elementos.custodia });
      this.setState({ custodiaNombre: this.props.elementos.custodiaNombre });
      this.setState({ nombreOperador: this.props.elementos.nombreOperador });
      this.setState({ checklist: this.props.elementos.checklist });
      this.setState({ full: this.props.elementos.full });
      this.setState({ actualizaFull: this.props.elementos.actualizaFull });
      this.setState({ caja: this.props.elementos.caja });
      this.setState({ economico: this.props.elementos.economico });
      this.setState({
        mbRutaTransporte: this.props.elementos.mbRutaTransporte,
      });
      this.setState({ mbTipoProveedor: this.props.elementos.mbTipoProveedor });
      this.setState({
        mbReferenciaAuxiliar: this.props.elementos.mbReferenciaAuxiliar,
      });
      this.setState({
        fechaInActCarga: this.props.elementos.fechaInActCarga
          ? new Date(this.props.elementos.fechaInActCarga)
          : "",
      });
      this.setState({
        fechaInActTransp: this.props.elementos.fechaInActTransp
          ? new Date(this.props.elementos.fechaInActTransp)
          : "",
      });
      this.setState({ grupoStr: this.props.elementos.grupo });
      this.MovimientosObject = this.props.elementos;
      this.cargaIdTransportistas(
        this.props.elementos.plataforma.id,
        this.props.elementos.plataforma.nombre
      );
      this.cargaIdOperadores(
        this.props.elementos.transportista.id,
        this.props.elementos.transportista.nombre
      );
      this.cargarOperacionestipo(
        this.props.elementos.plataforma.id,
        this.props.elementos.plataforma.nombre
      );

      if(this.MovimientosObject.personalizados)
        this.MovimientosObject.personalizados= JSON.parse(this.MovimientosObject.personalizados);

    } else {
      this.setState({ operacionEstatus: { value: 1, label: "SIN GPS" } });
      //this.MovimientosObject = {"id":0, "operacionTipo":{"id":0, "nombre":""}, "operacionEstatus":{"id":1, "nombre":"SIN GPS"}, "corredor":{"id":0, "nombre":""}, "operador":{"id":0, "nombre":""}, "plataforma":{"id":0, "nombre":""}, "transportista":{"id":0, "nombre":""}, "cartaPorte":"", "rutaInicio":"", "origen":"", "nota":"", "custodiaNombre":"", "deRegreso":0, "custodia":0, "checklist":0 }
    }

    /* Plataformas */
    this.plataforma.push({ value: 0, label: "Selecciona una Plataforma" });
    currentUser.map((elemento, index) => {
      if (
        elemento.plataforma.plataformaTipo.nombre.toUpperCase() === "TERRESTRE"
      ) {
        this.plataforma.push({
          value: elemento.plataforma.id,
          label: elemento.plataforma.nombre,
          data: elemento.plataforma,
        });
      }
    });

    /* Transportista */
    //this.transportista=[];
    /* this.props.stor.transportistas_object.map((transportista, index)=>{
       if(transportista.tipo===2){
         this.transportista.push({ value:transportista.id, label:transportista.nombre })  //comentado el 25/05/2021
       }
     });*/

    /* Operaciones Tipos */
    this.operacionTipo.push({
      value: 0,
      label: "Selecciona una Operación Tipo ",
    });
    /*this.props.stor.operacionesTipo_object.map((operacionTipo, index)=>{
      this.operacionTipo.push({ value:operacionTipo.id, label:operacionTipo.nombre })  //comentado el 25/05/2021

    });*/

    /* Operaciones Estatus */
    this.operacionEstatus.push({
      value: 0,
      label: "Selecciona una Operación Estatus ",
    });
    this.props.stor.operacionesEstatus_object.map((operacionEstatus, index) => {
      this.operacionEstatus.push({
        value: operacionEstatus.id,
        label: operacionEstatus.nombre,
      });
    });

    /* Operaciones Cliente ahora son Corredores */
    this.corredor.push({ value: 0, label: "Selecciona un Corredor " });

    this.props.stor.corredores_object.map((corredor, index) => {
      if (corredor.plataformaTipo.nombre === "Terrestre") {
        this.corredor.push({ value: corredor.id, label: corredor.nombre });
      }
    });

    /* Operadores */
    //this.operador.push({ value:0, label:"Selecciona un Operador " })
    /*
    this.operador.push({ value:1, label:"-1 OPERADOR SIN CATALOGO" });
    this.props.stor.operadores_object.map((operador, index)=>{
      if(this.MovimientosObject.transportista===operador.idTransportista){
          this.operador.push({ value:operador.id, label:(operador.noEmpleado?operador.noEmpleado:"")+" "+operador.nombre+" "+operador.paterno+" "+operador.materno})
      }
    });*/
  }

  guardarMovimiento = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };

  enableSave = () => {
    let habilitado = true;
    if (!(this.state.plataforma.value > 0)) {
      habilitado = false;
    }
    if (!(this.state.plataforma.value === 529)) {
      if (
        !(this.state.operacionTipo.value > 0) ||
        this.state.operacionTipo.value === "id"
      ) {
        habilitado = false;
      }
    }

    if (
      !(this.state.transportista.value > 0) ||
      this.state.transportista.value === "id"
    ) {
      habilitado = false;
    }

    if (
      !(this.state.operador.value > 0) ||
      this.state.operador.value === "id"
    ) {
      habilitado = false;
    }

    if (
      !(this.state.corredor.value > 0) ||
      this.state.corredor.value === "id"
    ) {
      habilitado = false;
    }

    if (this.state.plataforma.value === 529) {
      if (
        !(this.state.origenCatalogo.value > 0) ||
        !(this.state.modalidad.value > 0)
      ) {
        //MABE
        habilitado = false;
      }
    }

    return habilitado;
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if( stateVar === "variables" && returnVal){
                if ( !this.MovimientosObject.personalizados ) {
                  this.MovimientosObject.personalizados={};
                  for(let v of returnVal){
                    switch (v.tipo) {
                      case "String":  this.MovimientosObject.personalizados[v.nombre]= "";
                        break;
                      case "DateTime":  this.MovimientosObject.personalizados[v.nombre]= null;
                        break;
                      case "Number":  this.MovimientosObject.personalizados[v.nombre]= 0;
                        break;
                      case "Any":
                        switch (v.nombre) {
                          case "cuenta_espejo":
                            this.MovimientosObject.personalizados[v.nombre]={};
                            this.MovimientosObject.personalizados[v.nombre]["url"]= "";
                            this.MovimientosObject.personalizados[v.nombre]["usuario"]= "";
                            this.MovimientosObject.personalizados[v.nombre]["password"]= "";
                            break;
                          default:
                            break;
                        }
                        break;
                      case "Boolean": this.MovimientosObject.personalizados[v.nombre]= false;
                        break;
                      default:
                        break;
                    }
                  }
                }
                this.setState({ variables: returnVal});
              }
              if (stateVar === "corredores") {
                this.corredores = [];
                returnVal.map((corredor, index) =>
                  this.corredores.push({
                    value: corredor.id,
                    label: corredor.nombre,
                  })
                );
                this.setState({ corredores: this.corredores });
              }
              if (stateVar === "modalidades") {
                let modalidadesTemp = [];
                returnVal.map((l, index) =>
                  modalidadesTemp.push({
                    value: l.id,
                    label: l.nombre,
                    data: l,
                  })
                );
                this.setState({ modalidades: modalidadesTemp });
              }
              if (stateVar === "origenesCatalogo") {
                let origenesCatalogoTemp = [];
                returnVal.map((l, index) =>
                  origenesCatalogoTemp.push({
                    value: l.id,
                    label: l.nombre,
                    data: l,
                  })
                );
                this.setState({ origenesCatalogo: origenesCatalogoTemp });
              }
              if(stateVar === "movFull"){
                this.setState({ movFull: returnVal });
              }
              if (stateVar === "movimientosTipo") {
                let mTipoTemp = [];
                returnVal.map((mt, index) =>
                  mTipoTemp.push({ value: mt.id, label: mt.nombre, data: mt })
                );
                this.setState({ movimientoTipos: mTipoTemp });
              }
              if (stateVar === "traxion_grupos") {
                let gruposTemp = [];
                returnVal.map((grp, index) => {
                  gruposTemp.push({
                    value: grp.id,
                    label: grp.grupo + "-" + grp.base + "-" + grp.gerente,
                    data: grp,
                  });
                  if (
                    this.state.accion !== "nuevo" &&
                    this.props.elementos.grupo +
                      "-" +
                      this.props.elementos.base +
                      "-" +
                      this.props.elementos.gerente ===
                      grp.grupo + "-" + grp.base + "-" + grp.gerente
                  ) {
                    this.setState({
                      grupo: {
                        value: grp.id,
                        label: grp.grupo + "-" + grp.base + "-" + grp.gerente,
                        data: grp,
                      },
                    });
                  }
                });
                this.setState({ grupos: gruposTemp });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "terrestreMovimientos" && returnVal.id) {
                this.usuariosLogObject.idBitacoraMonitoreo = 1;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.id;
                this.usuariosLogObject.idPlataformaTipo = 2;
                this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                this.usuariosLogObject.descripcion = "Nuevo Movimiento";
                Crud_Catalogos(
                  "usuariosLog",
                  "bitacoraMov",
                  "post",
                  "",
                  this.usuariosLogObject
                );
              }
            }
            case "put":
              {
                if (stateVar === "terrestreMovimientos" && returnVal.id) {
                  this.usuariosLogObject.idBitacoraMonitoreo = 1;
                  this.usuariosLogObject.idUsuario = user.id;
                  this.usuariosLogObject.idMovimiento = returnVal.id;
                  this.usuariosLogObject.idPlataformaTipo = 2;
                  this.usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                  this.usuariosLogObject.descripcion ="Actualización de Movimiento";
                  Crud_Catalogos(
                    "usuariosLog",
                    "bitacoraMov",
                    "post",
                    "",
                    this.usuariosLogObject
                  );
                }
              }
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ....");
        }
      });
  };
  //************ Combos ***********************

  changeGrupo = (e) => {
    this.setState({ grupo: e });
    this.MovimientosObject.grupo = e.data.grupo;
    this.MovimientosObject.base = e.data.base;
    this.MovimientosObject.gerente = e.data.gerente;
  };
  changeGrupoStr = (e) => {
    this.setState({ grupoStr: e.target.value });
    this.MovimientosObject.grupo = e.target.value;
  };

  changePlataforma = (event) => {
    if (event.value === 193) {
      //193 Es traxion
      this.getfuntion("get", "", "", "traxion_grupos", "traxion_grupos");
    }
    if (event.value === 529) {
      //529 Es Mabe
      this.setState({
        movimientoTipo: { value: "", label: "Seleccione uno:" },
      });
      this.MovimientosObject.operacionTipo.id = 2;
      this.MovimientosObject.operacionTipo.nombre = "DESPACHO";
    } else {
      this.setState({
        movimientoTipo: {
          value: this.MovimientosObject.movimientoTipo.id,
          label: this.MovimientosObject.movimientoTipo.value,
        },
      });
      this.MovimientosObject.operacionTipo.id = this.state.operacionTipo.value;
      this.MovimientosObject.operacionTipo.nombre =
        this.state.operacionTipo.label;
    }
    this.setState({ plataforma: event });
    this.MovimientosObject.plataforma = event.data;
    this.cargaIdTransportistas(event.value, event.label);
    this.cargaIdCorredores(event.value, event.label);
    this.cargarOperacionestipo(event.value, event.label); //agregue esta linea
    this.getfuntion(
      "get",
      "",
      "",
      "variables/" + event.value,
      "variables"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "movimientosTipo/plataforma/" + event.value,
      "movimientosTipo"
    );
  };
  changeMovimientotipo = (event) => {
    this.setState({ movimientoTipo: event });
    this.MovimientosObject.movimientoTipo = event.data;
  };
  changeTransportista = (event) => {
    this.setState({
      transportista: { value: event.value, label: event.label },
    });
    this.MovimientosObject.transportista.id = event.value;
    this.MovimientosObject.transportista.nombre = event.label;
    this.cargaIdOperadores(event.value, event.label);

    if (event.data) {
      if (event.data.idModalidadMovimientoDefault) {
        this.state.modalidades.forEach((element) => {
          if (element.value == event.data.idModalidadMovimientoDefault) {
            this.setState({ modalidad: element });
            this.MovimientosObject.modalidad = element.data;
          }
        });
      }
    }
  };
  changeOperador = (event) => {
    this.setState({ operador: event });
    this.MovimientosObject.operador.id = event.value;
    this.MovimientosObject.nombreOperador = event.label;
  };
  changeOperacionTipo = (event) => {
    this.setState({
      operacionTipo: { value: event.value, label: event.label },
    });
    this.MovimientosObject.operacionTipo.id = event.value;
    this.MovimientosObject.operacionTipo.nombre = event.label;
  };
  changeOperacionEstatus = (event) => {
    this.setState({
      operacionEstatus: { value: event.value, label: event.label },
    });
    this.MovimientosObject.operacionEstatus.id = event.value;
    this.props.stor.operacionesEstatus_object.map((operacionEstatus, index) => {
      if (operacionEstatus.id === event.value) {
        this.operacionEstatus.push({
          value: operacionEstatus.id,
          label: operacionEstatus.nombre,
        });
      }
    });
  };
  changeCorredor = (event) => {
    this.setState({ corredor: { value: event.value, label: event.label } });
    this.MovimientosObject.corredor.id = event.value;
    this.MovimientosObject.corredor.nombre = event.label;
    this.props.stor.operacionesClientes_object.map((corredor, index) => {
      if (corredor.id === event.value) {
        this.corredor.push({ value: corredor.id, label: corredor.nombre });
      }
    });
  };

  //Campos Normales
  changeCartaPorte = (event) => {
    this.MovimientosObject.cartaPorte = event.target.value;
    this.setState({ cartaPorte: event.target.value });
  };
  changeCustodiaNombre = (event) => {
    this.MovimientosObject.custodiaNombre = event.target.value;
    this.setState({ custodiaNombre: event.target.value });
  };
  changeCustodia = (event) => {
    this.state.custodia === 0
      ? this.setState({ custodia: 1 })
      : this.setState({ custodia: 0 });
    this.state.custodia === 0
      ? (this.MovimientosObject.custodia = 1)
      : (this.MovimientosObject.custodia = 0);
  };
  changeChecklist = (event) => {
    this.state.checklist === 0
      ? this.setState({ checklist: 1 })
      : this.setState({ checklist: 0 });
    this.state.checklist === 0
      ? (this.MovimientosObject.checklist = 1)
      : (this.MovimientosObject.checklist = 0);
  };
  changeDeRegreso = (event) => {
    this.state.deRegreso === 0
      ? this.setState({ deRegreso: 1 })
      : this.setState({ deRegreso: 0 });
    this.state.deRegreso === 0
      ? (this.MovimientosObject.deRegreso = 1)
      : (this.MovimientosObject.deRegreso = 0);
  };
  changeNombreOperador = (event) => {
    if (event.target.value.length > 0) {
      this.setState({ comboOperadorEdited: true });
    } else {
      this.setState({ comboOperadorEdited: false });
    }
    this.MovimientosObject.nombreOperador = event.target.value;
    this.setState({ nombreOperador: event.target.value });
  };
  changeOrigen = (event) => {
    this.MovimientosObject.origen = event.target.value;
    this.setState({ origen: event.target.value });
  };
  changeCaja = (event) => {
    this.MovimientosObject.caja = event.target.value;
    this.setState({ caja: event.target.value });
  };
  changeEconomico = (event) => {
    this.MovimientosObject.economico = event.target.value;
    this.setState({ economico: event.target.value });
  };
  changeRuta = (event) => {
    this.MovimientosObject.ruta = event.target.value;
    this.setState({ ruta: event.target.value });
  };
  changeNota = (event) => {
    this.MovimientosObject.nota = event.target.value;
    this.setState({ nota: event.target.value });
  };
  changeRemolque1 = (event) => {
    this.MovimientosObject.remolque1 = event.target.value;
    this.setState({ remolque1: event.target.value });
  };
  changeRemolque2 = (event) => {
    this.MovimientosObject.remolque2 = event.target.value;
    this.setState({ remolque2: event.target.value });
  };
  changeCheckOperador = (event) => {
    this.state.checkOperador === 0
      ? this.setState({ checkOperador: 1 })
      : this.setState({ checkOperador: 0 });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  changeMbTipoProveedor = (event) => {
    this.MovimientosObject.mbTipoProveedor = event.target.value;
    this.setState({ mbTipoProveedor: event.target.value });
  };
  changeMbRutaTransporte = (event) => {
    this.MovimientosObject.mbRutaTransporte = event.target.value;
    this.setState({ mbRutaTransporte: event.target.value });
  };
  changeMbReferenciaAuxiliar = (event) => {
    this.MovimientosObject.mbReferenciaAuxiliar = event.target.value;
    this.setState({ mbReferenciaAuxiliar: event.target.value });
  };
  changeOrigenCatalogo = (event) => {
    this.setState({ origenCatalogo: event });
    this.MovimientosObject.origenCatalogoMabe = event.data;
    let origenTemp = "";
    if (this.state.accion !== "nuevo") {
      if (this.props.elementos.origen) {
        origenTemp = this.props.elementos.origen;
      }
    }

    if (origenTemp == "") {
      this.MovimientosObject.origen = event.label;
      this.setState({ origen: event.label });
    } else {
      console.log(origenTemp);
    }
  };

  changeOrigenCatalogoSuburbia = (event) => {

    this.setState({ origenCatalogoSuburbia: event });
    this.MovimientosObject.origenCatalogoSuburbia = event.data;

    this.setState({ origen: event });
    this.state.origen = event.data;


   // let origenTemp = "";
  //  console.log(event.label);
    //alert(this.MovimientosObject.origenCatalogoSuburbia);
    // if (this.state.accion !== "nuevo") {
    //   if (this.props.origenCatalogoSuburbia) {
    //     origenTemp = this.props.origenCatalogoSuburbia;
    //   }
    // }
       this.MovimientosObject.origenCatalogoSuburbia = event.label;
       this.MovimientosObject.origen = event.label;

    // if (origenTemp == "") {
    //   this.MovimientosObject.origenCatalogoSuburbia = event.label;
    //   this.setState({ origen: event.label });
    // }
  };


  changeFechaInActCarga = (date) => {
    this.MovimientosObject.fechaInActCarga = timeToDateFill(
      new Date(date).getTime()
    );
    this.setState({ fechaInActCarga: date });
  };

  changeFechaInActTransp = (date) => {
    this.MovimientosObject.fechaInActTransp = timeToDateFill(
      new Date(date).getTime()
    );
    this.setState({ fechaInActTransp: date });
  };

  delete() {
    //this.props.getfuntion("delete", this.MovimientosObject.id, this.state.idMovimiento);
    this.MovimientosObject.operacionEstatus = { id: 4, nombre: "Eliminado" };
    this.props.getfuntion("put", this.MovimientosObject);
  }
  crud_put() {
    //this.props.Fclose();
    let copyToSave= {...this.MovimientosObject};
    if(copyToSave.personalizados)
      copyToSave.personalizados= JSON.stringify(copyToSave.personalizados);
    this.getfuntion(
      "put",
      copyToSave,
      "",
      "terrestreMovimientos",
      "terrestreMovimientos"
    );
    //this.props.getfuntion("put", this.MovimientosObject);
  }
  crud_post() {
    //this.props.Fclose();
    let copyToSave= {...this.MovimientosObject};
    if(copyToSave.personalizados)
      copyToSave.personalizados= JSON.stringify(copyToSave.personalizados);
    this.getfuntion(
      "post",
      copyToSave,
      "",
      "terrestreMovimientos",
      "terrestreMovimientos"
    );
    //this.props.getfuntion("post", this.MovimientosObject);
  }

  getVariableValor=(variables, nombre)=>{
    const nombreTemp= nombre.split(".");
    if( nombreTemp ){
      if(1 === nombreTemp.length)
        return variables[nombreTemp[0]];
      if(2 === nombreTemp.length)
        return variables[nombreTemp[0]]? variables[nombreTemp[0]][nombreTemp[1]]: null;
        //return variables[nombreTemp[0]][nombreTemp[1]];
      if(3 === nombreTemp.length)
        return  variables[nombreTemp[0]] ?
                variables[nombreTemp[0][nombreTemp[1]]]
                  ? variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]
                :null
              :null;
        //return variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]];
    }
    return variables[nombre];
  }
  getForm=(tipo, longitud, nombre, etiqueta)=>{
    {/*

                      variables: {
                        ejecutivo: "",
                        referencia_facturacion: "",
                        referencia_chubb: "",
                        cita_colocacion: null,
                        cuenta_espejo:{
                          url:"",
                          usuario:"",
                          password: ""},
                        modalidad: false
                      } */}
    let valor= null;
    if( this.MovimientosObject.personalizados ){
      valor= this.getVariableValor(this.MovimientosObject.personalizados, nombre)
    }
    switch (tipo) {
      case "String":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="text"
                value={ this.MovimientosObject.personalizados && valor ? valor : "" }//{this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "Number":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="text"
                value={ this.MovimientosObject.personalizados && valor ? valor : "0" }//{this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "DateTime":
        return(
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                value={ this.MovimientosObject.personalizados && valor ? new Date(valor) :null }//{this.state[nombre]}
                onChange={(e)=>{
                  const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0]]= e ?timeToDateFill( new Date(e).getTime()): e;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0]][nombreTemp[1]]= e ?timeToDateFill( new Date(e).getTime()): e;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.personalizados[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e ?timeToDateFill( new Date(e).getTime()): e;
                }}
              />
            </Form.Group>
          </Grid.Col>)
      case "Any":
        switch (nombre) {
          case "cuenta_espejo":
            let colsTemp= [] ;
            colsTemp.push(this.getForm("String", 400, "cuenta_espejo.url", "Url (Cuenta espejo)"));
            colsTemp.push(this.getForm("String", 200, "cuenta_espejo.usuario", "Usuario (Cuenta espejo)"));
            colsTemp.push(this.getForm("String", 200, "cuenta_espejo.password", "Password (Cuenta espejo)"));
            return colsTemp;
          default:
            break;
        }
        break;
      case "Boolean":
        return(
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              <Form.Switch
                name={nombre}
                checked={ valor }
                //label= { valor? "Full": "Sencillo" }
                onChange={(e)=>{
                  const nombreTemp= nombre.split(".");
                  //this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.checked });
                  if(1 === nombreTemp.length)
                    this.MovimientosObject.personalizados[nombreTemp[0]]= e.target.checked;
                  if(2 === nombreTemp.length)
                    this.MovimientosObject.personalizados[nombreTemp[0]][nombreTemp[1]]= e.target.checked;
                  if(3 === nombreTemp.length)
                    this.MovimientosObject.personalizados[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.checked;
                }}
              />
            </Form.Group>
          </Grid.Col>)
      default:
        break;
    }
  }
  //<Grid.Col md={4}><Form.Group label="Ruta Inicio">     <DateTimePicker format="y-MM-dd h:mm:ss a" value={this.state.rutaInicio} onChange={this.onChangeRutaInicio} /></Form.Group></Grid.Col>
  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>Movimiento ID {this.props.elementos.id}</h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={4}>
                <Form.Group
                  label={
                    <strong>
                      Plataforma<label className="text-danger">*</label>
                    </strong>
                  }
                >
                  <Select
                    value={this.state.plataforma}
                    onChange={this.changePlataforma}
                    options={this.plataforma}
                  />
                </Form.Group>
              </Grid.Col>
              {this.state.plataforma.value === 529 ? (
                <Grid.Col md={4}>
                  <Form.Group label="Tipo de movimiento">
                    <Select
                      value={this.state.movimientoTipo}
                      onChange={this.changeMovimientotipo}
                      options={this.state.movimientoTipos}
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              <Grid.Col md={4}>
                <Form.Group
                  label={
                    <strong>
                      Transportistas<label className="text-danger">*</label>
                    </strong>
                  }
                >
                  <Select
                    value={this.state.transportista}
                    onChange={this.changeTransportista}
                    options={this.state.transportistas}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group
                  label={
                    <strong>
                      Operador<label className="text-danger">*</label>
                    </strong>
                  }
                >
                  <Select
                    value={this.state.operador}
                    onChange={this.changeOperador}
                    options={this.state.operadores}
                    isDisabled={this.state.comboOperadorEdited}
                  />
                </Form.Group>
                {this.state.operador.value === 1 ? (
                  <Form.Input
                    name="OperadorNombre"
                    value={this.state.nombreOperador}
                    type="text"
                    onChange={this.changeNombreOperador}
                    maxlength="20"
                  />
                ) : (
                  ""
                )}
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group
                  label={
                    <strong>
                      Operación Cliente (Corredores)
                      <label className="text-danger">*</label>
                    </strong>
                  }
                >
                  <Select
                    value={this.state.corredor}
                    onChange={this.changeCorredor}
                    options={this.corredor}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Operación Estatus">
                  <Select
                    value={this.state.operacionEstatus}
                    onChange={this.changeOperacionEstatus}
                    options={this.operacionEstatus}
                    isDisabled={true}
                  />
                </Form.Group>
              </Grid.Col>
              {!(this.state.plataforma.value === 529) ? ( //529 es mabe
                <Grid.Col md={3} lg={4}>
                  <Form.Group
                    label={
                      <strong>
                        Operacion Tipo<label className="text-danger">*</label>
                      </strong>
                    }
                  >
                    <Select
                      value={this.state.operacionTipo}
                      onChange={this.changeOperacionTipo}
                      options={this.operacionTipo}
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {[25,529].includes( this.state.plataforma.value)? ( //529 es mabe
                <Grid.Col md={3} lg={4}>
                  <Form.Group
                    label={
                      <strong>
                        Modalidad<label className="text-danger">*</label>
                      </strong>
                    }
                  >
                    <Select
                      value={this.state.modalidad}
                      onChange={(e) => {
                        this.setState({ modalidad: e });
                        this.MovimientosObject.modalidad = e.data;
                      }}
                      options={this.state.modalidades}
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {this.state.plataforma.value === 193 ? ( //193 Es traxion
                <Grid.Col md={3} lg={4}>
                  <Form.Group label="Grupo ( GRUPO-BASE-GERENTE)">
                    <Select
                      value={this.state.grupo}
                      onChange={this.changeGrupo}
                      options={this.state.grupos}
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {this.state.plataforma.value === 557 ? ( //557 Es Egoba
                <Grid.Col md={3}>
                  <Form.Group label="Grupo">
                    {" "}
                    <Form.Input
                      name="grupo"
                      type="text"
                      value={this.state.grupoStr}
                      onChange={this.changeGrupoStr}
                      maxlength="200"
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {[193].includes(this.state.plataforma.value) ? ( //193 Es traxion
                <Grid.Col md={4}>
                  <Form.Group label="Ruta">
                    {" "}
                    <Form.Input
                      name="ruta"
                      type="text"
                      value={this.state.ruta}
                      onChange={this.changeRuta}
                      maxlength="20"
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {this.state.plataforma.value === 529 ? ( //529 Es mabe
                <Grid.Col md={4}>
                  <Form.Group label="Ruta p.transporte">
                    {" "}
                    <Form.Input
                      name="mbRutaTransporte"
                      type="text"
                      value={this.state.mbRutaTransporte}
                      onChange={this.changeMbRutaTransporte}
                      maxlength="20"
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {[193, 490, 557, 25, 529, 226].includes(
                this.state.plataforma.value
              ) ? ( //193 Es traxion
                <Grid.Col md={4}>
                  <Form.Group label="Caja">
                    {" "}
                    <Form.Input
                      name="caja"
                      type="text"
                      value={this.state.caja}
                      onChange={this.changeCaja}
                      maxlength="20"
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {[193, 490, 557, 25, 820, 226].includes(this.state.plataforma.value) ? ( //490 Traxion Goodyear
                <Grid.Col md={4}>
                  <Form.Group label="Economico">
                    {" "}
                    <Form.Input
                      name="economico"
                      type="text"
                      value={this.state.economico}
                      onChange={this.changeEconomico}
                      maxlength="20"
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}

              <Card title="Datos">
                <Grid.Col md={12} xl={12}>
                  <Grid.Row>
                    <Grid.Col md={4}>
                      <Form.Group
                        label={
                          this.state.plataforma.value === 529
                            ? "Entrega (identificador)"
                            : "Carta Porte"
                        }
                      >
                        <Form.Input
                          name="cartaPorte"
                          value={this.state.cartaPorte}
                          type="text"
                          onChange={this.changeCartaPorte}
                          maxlength="20"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group
                        label={
                          this.state.plataforma.value === 529
                            ? "Origen SAP"
                            : "Origen"
                        }
                      >
                        {this.state.plataforma.value === 529 ? (
                          <div align="center">
                            <strong>{this.state.origen}</strong>
                          </div>
                        ) : this.state.plataforma.value === 23 ?    //plataforma Suburbia
                        <Form.Group
                          //  label={
                          //   <strong>
                          //      Origen<label className="text-danger">*</label>
                          //      </strong>
                          //  }
                           >
                          <Select
                            // value={this.state.origenCatalogoSuburbia}//esto parece correcto
                            value={this.state.origen}//
                            onChange={this.changeOrigenCatalogoSuburbia}
                            options={this.state.origenCatalogoSuburbia}//origenes: en plural !
                          />
                      </Form.Group>:(
                          <Form.Input
                            name="origen"
                            type="text"
                            value={this.state.origen}
                            onChange={this.changeOrigen}
                            maxlength="20"
                          />
                        )}
                      </Form.Group>
                    </Grid.Col>
                    {this.state.plataforma.value === 9 ? (
                      <Grid.Col md={4}>
                        <Form.Group label="Remolque1">
                          {" "}
                          <Form.Input
                            name="remolque1"
                            type="text"
                            value={this.state.remolque1}
                            onChange={this.changeRemolque1}
                            maxlength="20"
                          />
                        </Form.Group>
                      </Grid.Col>
                    ) : null}
                    {this.state.plataforma.value === 9 ? (
                      <Grid.Col md={4}>
                        <Form.Group label="Remolque2">
                          {" "}
                          <Form.Input
                            name="remolque2"
                            type="text"
                            value={this.state.remolque2}
                            onChange={this.changeRemolque2}
                            maxlength="20"
                          />
                        </Form.Group>
                      </Grid.Col>
                    ) : null}

                    {this.state.plataforma.value === 529 ? ( //529 Es mabe
                      <Grid.Col md={4}>
                        <Form.Group label="Tipo de Proveedor">
                          {" "}
                          <Form.Input
                            name="mbTipoProveedor"
                            type="text"
                            value={this.state.mbTipoProveedor}
                            onChange={this.changeMbTipoProveedor}
                            maxlength="20"
                          />
                        </Form.Group>
                      </Grid.Col>
                    ) : null}
                    {this.state.plataforma.value === 529 ? ( //529 Es mabe
                      <Grid.Col md={4}>
                        <Form.Group label="Referencia auxiliar">
                          {" "}
                          <Form.Input
                            name="mbReferenciaAuxiliar"
                            type="text"
                            value={this.state.mbReferenciaAuxiliar}
                            onChange={this.changeMbReferenciaAuxiliar}
                            maxlength="20"
                          />
                        </Form.Group>
                      </Grid.Col>
                    ) : null}
                    {this.state.plataforma.value === 529 ? ( //529 Es mabe
                      <Grid.Col md={4}>
                        <Form.Group
                          label={
                            <strong>
                              Origen por catálogo
                              <label className="text-danger">*</label>
                            </strong>
                          }
                        >
                          <Select
                            value={this.state.origenCatalogo}
                            onChange={this.changeOrigenCatalogo}
                            options={this.state.origenesCatalogo}
                          />
                        </Form.Group>
                      </Grid.Col>
                    ) : null}
                    <Grid.Col md={12}>
                      <Form.Group label="Nota">
                        {" "}
                        <Form.Input
                          name="nota"
                          type="text"
                          value={this.state.nota}
                          onChange={this.changeNota}
                          maxlength="30"
                        />
                      </Form.Group>
                    </Grid.Col>
                    {this.state.variables.map((v, inex)=>
                      this.getForm(v.tipo, v.longitud, v.nombre, v.etiqueta)
                      )}
                  </Grid.Row>
                  {this.state.plataforma.value === 529 ? ( //529 Es mabe
                    <Grid.Row>
                      <Grid.Col md={6}>
                        <Form.Group label="In. Act. Carga">
                          {" "}
                          <DateTimePicker
                            format="y-MM-dd h:mm:ss a"
                            value={this.state.fechaInActCarga}
                            onChange={this.changeFechaInActCarga}
                            maxlength="20"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6}>
                        <Form.Group label="In. Act. Transp.">
                          {" "}
                          <DateTimePicker
                            format="y-MM-dd h:mm:ss a"
                            value={this.state.fechaInActTransp}
                            onChange={this.changeFechaInActTransp}
                            maxlength="20"
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  ) : null}
                </Grid.Col>
              </Card>

              {this.state.accion !== "nuevo" ? (
                <Card title="Cheks" visible="false">
                  <Grid.Row md={12} xl={12}>
                    <Grid.Col md={4} xl={4}>
                      <Form.Group label="De Regreso">
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value={this.state.deRegreso}
                          checked={this.state.deRegreso}
                          onChange={this.changeDeRegreso}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4} xl={4}>
                      <Form.Group label="Custodia">
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value={this.state.custodia}
                          checked={this.state.custodia}
                          onChange={this.changeCustodia}
                        />
                        {this.state.custodia > 0 ? (
                          <Form.Input
                            name="CustodiaNombre"
                            value={this.state.custodiaNombre}
                            type="text"
                            onChange={this.changeCustodiaNombre}
                            maxlength="20"
                          />
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4} xl={4}>
                      <Form.Group label="CheckList">
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value={this.state.checklist}
                          checked={this.state.checklist}
                          onChange={this.changeChecklist}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>{console.log(this.props.elementos,this.state.movFull)}
                  { this.state.accion !== "nuevo" && [25,529].includes( this.state.plataforma.value)?
                    <Grid.Row>
                      { /*full: null, actualizaFull: false,*/}
                      <Grid.Col md={4} xl={4}>
                        <Form.Group label="Full con">
                          <Form.Input
                            name="full"
                            type="text"
                            value={this.state.full}
                            onChange={(e)=>{
                              this.setState({full: e.target.value});
                              this.MovimientosObject.full= e.target.value;
                              this.getfuntion(
                                "get",
                                "",
                                "",
                                "terrestreMovimientos/" + e.target.value,
                                "movFull"
                              );
                            }}
                            maxlength="30"
                          />
                          <label>{this.state.movFull && this.state.movFull.id? this.state.movFull.cartaPorte:""}</label>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={4} xl={4}>
                        <Form.Group label="Actualizar full">
                          <Form.Switch
                            type="Checkbox"
                            name="toggle"
                            value={this.state.actualizaFull}
                            checked={this.state.actualizaFull}
                            onChange={(e)=>{
                              this.setState({actualizaFull: e.target.checked});
                              this.MovimientosObject.actualizaFull= e.target.checked;
                            }}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    : null
                  }
                </Card>
              ) : null}
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <strong>
            Los campos marcados con: <label className="text-danger">*</label>,
            son obligatorios
          </strong>
        </Grid.Row>
        <div className="float-right">
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={!this.enableSave()}
              onClick={this.guardarMovimiento}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
          {this.state.accion === "modificar" ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default MonitoreoTerrestreForm;
