import React, { useState, useEffect } from "react";
import { Modelo, NumerosInternos } from ".";

const Chatbot =({ plataforma, stor })=> {
  const [tab, setTab]= useState(1);

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col"></div>
              <div className="col">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=>{ setTab(1) }}> Numeros internos</a>
                  </li>{/*
                  <li className="nav-item">
                    <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(2)}>Modelos</a>
                  </li>*/}
                </ul>
            </div>
            <div className="col"></div>
          </div>
          <div  hidden={tab !== 1}>
            <NumerosInternos
              stor= { stor }
              plataforma= { plataforma }
            />
          </div>
          {/*
          <div  hidden={tab !== 2}>
            <Modelo
              stor= { stor }
              plataforma= { plataforma }
              whatsappConfiguracion= {whatsappConfiguracion}
            />
          </div>*/}
        </div>
      </div>
    </div>
  )
}

export default Chatbot
