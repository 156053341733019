import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Paper, Avatar } from "@mui/material";
import { AltRoute, SignalWifiOff, PhoneDisabled, StopCircle, Report, Timer, Room, LocalShipping, MeetingRoom } from "@mui/icons-material";
import Crud_Catalogos from '../../herramientas/Crud_Catalogos';
import ListarMovimientosPanelSupervisor from '../../components/ListarMovimientosPanelSupervisor';
import SiteWrapper from '../../SiteWrapper.react';

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var idAlertaCategoriaDesvioRuta = 1298;
var idAlertaCategoriaGpsSinSenal = 37;
var idAlertaCategoriaUnidadDetenida = 1288;
var idIncidencia1 = 4343;
var idIncidencia2 = 304;
var idIncidencia3 = 320;

const PanelSupervisor = () => {
  //console.log(currentUser[0].usuario.id);
  //console.log(idAlertaCategoriaDesvioRuta);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [conteoMovimientosAlertaCategoriaDesvioRuta, setConteoMovimientosAlertaCategoriasDesvioRuta] = useState(null);
  const [conteoMovimientosIncidenciaZonaIntermitencia, setConteoMovimientosIncidenciaZonaIntermitencia] = useState(null);
  const [conteoMovimientosAlertaCategoriaGpsSinSenal, setConteoMovimientosAlertaCategoriasGpsSinSenal] = useState(null);
  const [conteoMovimientosAlertaCategoriaUnidadDetenida, setConteoMovimientosAlertaCategoriasUnidadDetenida] = useState(null);
  const [conteoMovimientosIncidenciaUnidadOperativo, setConteoMovimientosIncidenciaUnidadOperativo] = useState(null);
  const [conteoMovimientosTemporizadores, setConteoMovimientosTemporizadores] = useState(null);
  const [conteoMovimientosConGps, setConteoMovimientosConGps] = useState(null);
  const [conteoMovimientosSinGps, setConteoMovimientosSinGps] = useState(null);
  const [conteoMovimientosUnidadProgramadaGps, setConteoMovimientosUnidadProgramadaGps] = useState(null);

  useEffect(() => {
    crud("get", "", "", "navieraMovimientos/conteoMovimientosNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoriaDesvioRuta, "conteoMovimientosAlertaCategoriaDesvioRuta");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosIncidenciaNavieras/" + idIncidencia1 + "/" + idIncidencia2 + "/" + currentUser[0].usuario.id, "conteoMovimientosIncidenciaZonaIntermitencia");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoriaGpsSinSenal, "conteoMovimientosAlertaCategoriaGpsSinSenal");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoriaUnidadDetenida, "conteoMovimientosAlertaCategoriaUnidadDetenida");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosIncidenciaNavieras/" + idIncidencia3 + "/0/" + currentUser[0].usuario.id, "conteoMovimientosIncidenciaUnidadOperativo");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosTemporizadoresNavieras/" +  currentUser[0].usuario.id, "conteoMovimientosTemporizadores");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosGpsNavieras/" +  currentUser[0].usuario.id, "conteoMovimientosConGps");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosSinGpsNavieras/" +  currentUser[0].usuario.id, "conteoMovimientosSinGps");
    crud("get", "", "", "navieraMovimientos/conteoMovimientosUnidadProgramadaConGpsNavieras/" +  currentUser[0].usuario.id, "conteoMovimientosUnidadProgramadaGps");
  }, []);

  const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {    
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get": 
            if(stateVar === "conteoMovimientosAlertaCategoriaDesvioRuta" && returnVal) {
              //console.log(returnVal);              
              setConteoMovimientosAlertaCategoriasDesvioRuta(returnVal);
            }
            if(stateVar === "conteoMovimientosAlertaCategoriaGpsSinSenal" && returnVal) {
              //console.log(returnVal);              
              setConteoMovimientosAlertaCategoriasGpsSinSenal(returnVal);
            }
            if(stateVar === "conteoMovimientosAlertaCategoriaUnidadDetenida" && returnVal) {
              //console.log(returnVal);              
              setConteoMovimientosAlertaCategoriasUnidadDetenida(returnVal);
            }
            if(stateVar === "conteoMovimientosIncidenciaZonaIntermitencia" && returnVal) {
              //console.log("movimientos zona intermitencia: " + returnVal);                          
              setConteoMovimientosIncidenciaZonaIntermitencia(returnVal);
            }
            if(stateVar === "conteoMovimientosIncidenciaUnidadOperativo" && returnVal) {
              //console.log("movimiento unidad en operativo: " + returnVal);                          
              setConteoMovimientosIncidenciaUnidadOperativo(returnVal);
            }
            if(stateVar === "conteoMovimientosTemporizadores" && returnVal) {
              //console.log("movimiento con temporizadores: " + returnVal);                          
              setConteoMovimientosTemporizadores(returnVal);
            }
            if(stateVar === "conteoMovimientosConGps" && returnVal) {
              //console.log("movimiento con gps: " + returnVal);                          
              setConteoMovimientosConGps(returnVal);
            }
            if(stateVar === "conteoMovimientosSinGps" && returnVal) {
              //console.log("movimiento sin gps: " + returnVal);                          
              setConteoMovimientosSinGps(returnVal);
            }
            if(stateVar === "conteoMovimientosUnidadProgramadaGps" && returnVal) {
              //console.log("movimiento unidad programada con gps: " + returnVal);                          
              setConteoMovimientosUnidadProgramadaGps(returnVal);
            }
            break;            
            default:
                break;
        }
    }).catch(err => { console.log(err); });
  }  

  const items = [
    { icon: <AltRoute color="error" fontSize="large"/>, count: conteoMovimientosAlertaCategoriaDesvioRuta ? conteoMovimientosAlertaCategoriaDesvioRuta : 0, text: "Desvío de ruta" },
    { icon: <SignalWifiOff color="error" fontSize="large"/>, count: conteoMovimientosIncidenciaZonaIntermitencia ? conteoMovimientosIncidenciaZonaIntermitencia : 0, text: "GPS excede tiempo en zona de intermitencia" },
    { icon: <PhoneDisabled color="error" fontSize="large"/>, count: conteoMovimientosAlertaCategoriaGpsSinSenal ? conteoMovimientosAlertaCategoriaGpsSinSenal : 0, text: "GPS sin señal" },
    { icon: <StopCircle color="error" fontSize="large"/>, count: conteoMovimientosAlertaCategoriaUnidadDetenida ? conteoMovimientosAlertaCategoriaUnidadDetenida : 0, text: "Unidad detenida" },
    { icon: <Report color="error" fontSize="large"/>, count: conteoMovimientosIncidenciaUnidadOperativo ? conteoMovimientosIncidenciaUnidadOperativo : 0, text: "Solicitud de operativo" },
  ];
  
  const status = [
    { icon: <Timer color="error" fontSize="large"/>, count: conteoMovimientosTemporizadores ? conteoMovimientosTemporizadores : 0, text: "Temporizador" },
    { icon: <Room color="error" fontSize="large"/>, count: conteoMovimientosConGps ? conteoMovimientosConGps : 0, text: "Servicios con GPS" },
    { icon: <MeetingRoom color="error" fontSize="large"/>, count: conteoMovimientosSinGps ? conteoMovimientosSinGps : 0, text: "Servicios sin GPS" },
    { icon: <LocalShipping color="error" fontSize="large"/>, count: conteoMovimientosUnidadProgramadaGps ? conteoMovimientosUnidadProgramadaGps : 0, text: "UNIDAD PROGRAMADA" },
  ];

  const handleClick = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  return (
    <>
      <SiteWrapper>
       <Paper elevation={3} sx={{ maxWidth: 1200, margin: "auto", p: 4, borderRadius: 2 }}>
        <Typography variant="h4" align="center" fontWeight="bold" mb={2}>
            PANEL DE SUPERVISOR
        </Typography>
        <hr/>
        <Grid container spacing={2}>
            {items.map((item, index) => (
            <Grid item xs={6} key={index} display="flex" alignItems="center" onClick={() => handleClick(item)}>
                <Avatar sx={{ bgcolor: "transparent", width: 56, height: 56}} >{item.icon}</Avatar>
                <Typography variant="h6" sx={{ ml: 2 }}>
                <strong>{item.count}</strong> {item.text}
                </Typography>                
            </Grid>
            ))}

            {status.map((item, index) => (
            <Grid item xs={6} key={index} display="flex" alignItems="center" onClick={() => handleClick(item)}>
                <Avatar sx={{ bgcolor: "transparent", width: 56, height: 56 }}>{item.icon}</Avatar>
                <Typography variant="h6" sx={{ ml: 2 }}>
                <strong>{item.count}</strong> {item.text}
                </Typography>
            </Grid>
            ))}
        </Grid>
        </Paper>

        <Dialog open={open} onClose={handleClose} 
          sx={{ 
            "& .MuiDialog-paper": { 
              width: "70vw",  // 70% del ancho de la ventana
              height: "70vh", // 70% de la altura de la ventana
              maxWidth: "none" 
            } 
          }}
        >
          <DialogTitle color="primary">Listado de los Movimientos con <p>{selectedItem ?selectedItem.text : ""}</p></DialogTitle>
          <DialogContent>
            <ListarMovimientosPanelSupervisor 
              categoria={selectedItem ? selectedItem.text : ""}
              idAlertaCategoriaDesvioRuta={idAlertaCategoriaDesvioRuta ? idAlertaCategoriaDesvioRuta : 0}
              idAlertaCategoriaGpsSinSenal={idAlertaCategoriaGpsSinSenal ? idAlertaCategoriaGpsSinSenal : 0}
              idAlertaCategoriaUnidadDetenida={idAlertaCategoriaUnidadDetenida ? idAlertaCategoriaUnidadDetenida : 0}  
              idIncidencia1={idIncidencia1 ? idIncidencia1 : 0}
              idIncidencia2={idIncidencia2 ? idIncidencia2 : 0}
              idIncidencia3={idIncidencia3 ? idIncidencia3 : 0}         
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cerrar</Button>
          </DialogActions>
        </Dialog>
        </SiteWrapper>
    </>
  )
}

export default PanelSupervisor;
