import React,  { useState, useEffect } from 'react';
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import { Table } from 'tabler-react';

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

const ListarMovimientosPanelSupervisor = ({categoria, idAlertaCategoriaDesvioRuta, idAlertaCategoriaGpsSinSenal, idAlertaCategoriaUnidadDetenida, idIncidencia1, idIncidencia2, idIncidencia3}) => {
  //console.log(categoria);
  //console.log(idAlertaCategoriaDesvioRuta);

  const [listarMovimientos, setListarMovimientos] = useState([]);
  const [mostrarMensaje, setMostrarMensaje] = useState(false);

  useEffect(() => {
    if(categoria === "Desvío de ruta"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoriaDesvioRuta, "listaMovimientosAlertaCategoriaDesvioRuta");
    } 
    if(categoria === "GPS excede tiempo en zona de intermitencia"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosIncidenciaNavieras/" + idIncidencia1 + "/" + idIncidencia2 + "/" + currentUser[0].usuario.id, "listaMovimientosIncidenciaZonaIntermitencia");
    }
    if(categoria === "Solicitud de operativo"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosIncidenciaNavieras/" + idIncidencia3 + "/0/" + currentUser[0].usuario.id, "listaMovimientosIncidenciaUnidadOperativo");
    }
    if(categoria === "Temporizador"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosTemporizadoresNavieras/" +  currentUser[0].usuario.id, "listaMovimientosTemporizadores");
    }
    if(categoria === "Servicios con GPS"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosGpsNavieras/" +  currentUser[0].usuario.id, "listaMovimientosConGps");
    }
    if(categoria === "Servicios sin GPS"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosSinGpsNavieras/" +  currentUser[0].usuario.id, "listaMovimientosSinGps");
    }
    if(categoria === "UNIDAD PROGRAMADA"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosUnidadProgramadaConGpsNavieras/" +  currentUser[0].usuario.id, "listaMovimientosUnidadProgramadaGps");
    }
    if(categoria === "GPS sin señal"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoriaGpsSinSenal, "listaMovimientosAlertaCategoriaGpsSinSenal");
    } 
    if(categoria === "Unidad detenida"){
      crud("get", "", "", "navieraMovimientos/listaMovimientosNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoriaUnidadDetenida, "listaMovimientosAlertaCategoriaUnidadDetenida");
    } 
  }, [categoria]);

  useEffect(() => {
    if (listarMovimientos.length === 0) {
      const timer = setTimeout(() => {
        setMostrarMensaje(true);
      }, 2000); // Retraso de 2 segundos

      return () => clearTimeout(timer); // Limpieza del timer al desmontar
    } else {
      setMostrarMensaje(false);
    }
  }, [listarMovimientos]);

  const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": 
          if(stateVar === "listaMovimientosAlertaCategoriaDesvioRuta" || stateVar === "listaMovimientosIncidenciaZonaIntermitencia" 
            || stateVar === "listaMovimientosIncidenciaUnidadOperativo" || stateVar === "listaMovimientosTemporizadores" 
            || stateVar === "listaMovimientosConGps" || stateVar === "listaMovimientosSinGps" || stateVar === "listaMovimientosUnidadProgramadaGps"
            || stateVar === "listaMovimientosAlertaCategoriaGpsSinSenal" || stateVar === "listaMovimientosAlertaCategoriaUnidadDetenida" ) {
            let listarMovimientosTemp = [];
            returnVal.map((i, index) => listarMovimientosTemp.push(i));
            //console.log(returnVal);
            setListarMovimientos(listarMovimientosTemp);
          }
            break;          
          default:
            break;
      }
    }).catch(err => { console.log(err); });
}  

  return (
    <>
      {
        listarMovimientos.length > 0 ? (
          <Table cards={true} striped={true} responsive={true} className="table-vcenter">
            <Table.Header>
              <Table.ColHeader>PLATAFORMA</Table.ColHeader>
              <Table.ColHeader>IDMOV</Table.ColHeader>               
              <Table.ColHeader>GPS</Table.ColHeader> 
              <Table.ColHeader>Cliente</Table.ColHeader> 
              {/*<Table.ColHeader>BOOKING</Table.ColHeader>
              <Table.ColHeader>PO</Table.ColHeader>
              <Table.ColHeader>CONTENEDOR</Table.ColHeader>   
              <Table.ColHeader>ESTATUS OPERACION</Table.ColHeader>*/} 
              {/*categoria === "Servicios con GPS" ? <Table.ColHeader>GPS</Table.ColHeader> : null*/}
              {/*(categoria === "Desvío de ruta" || categoria === "GPS sin señal" || categoria === "Unidad detenida") ? <Table.ColHeader>CATEGORIA ALERTA</Table.ColHeader> : null*/} 
              {/*categoria === "UNIDAD PROGRAMADA" ? <Table.ColHeader>ESTATUS RUTA</Table.ColHeader> : null*/}                          
              {/*<Table.ColHeader>CREACION MOVIMIENTO</Table.ColHeader>
              <Table.ColHeader>INICO RUTA</Table.ColHeader>  
              <Table.ColHeader>CITA PROGRAMADA</Table.ColHeader>*/}               
            </Table.Header>    
            {listarMovimientos.map((elemento, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Col>{elemento.plataforma}</Table.Col>
                  <Table.Col>{elemento.id_movimiento}</Table.Col>
                  <Table.Col>{elemento.nombre_gps}</Table.Col>
                  <Table.Col>{elemento.cliente}</Table.Col>
                  {/*<Table.Col>{elemento.booking}</Table.Col>
                  <Table.Col>{elemento.po}</Table.Col>
                  <Table.Col>{elemento.contenedor}</Table.Col>
                  <Table.Col>{elemento.operacion_estatus}</Table.Col>*/}
                  {/*categoria === "Servicios con GPS" ? <Table.Col>{elemento.nombre_gps}</Table.Col> : null*/} 
                  {/*(categoria === "Desvío de ruta" || categoria === "GPS sin señal" || categoria === "Unidad detenida") ? <Table.Col>{elemento.alerta_categoria}</Table.Col> : null*/}
                  {/*categoria === "UNIDAD PROGRAMADA" ? <Table.Col>{elemento.ruta_estatus}</Table.Col> : null*/}              
                  {/*<Table.Col>{elemento.fecha_creacion}</Table.Col>
                  <Table.Col>{elemento.inicio_ruta}</Table.Col>
                  <Table.Col>{elemento.cita_programada}</Table.Col>*/}
                </Table.Row>
              );
            })
            }    
          </Table>
        ) : (
          mostrarMensaje && (
            <div className="alert alert-primary" style={{ color: "blue" }}>
              No hay movimientos con {categoria}
            </div>
          )
        )
      }      
    </>
  )
}

export default ListarMovimientosPanelSupervisor;
