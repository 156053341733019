import React, { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { timeToDateNumbers } from "../herramientas/DateFormat";
/**
 * Lista de elementos json []
 * columnas (encabezados)
 * id (identificador de fila)
 * <TablePageable
      lista= { unidades }
      columnas={
        [
          { columna: "id", label:"ID" },
          { columna: "nombre", label:"NOMBRE" },
          { columna: "placa", label:"PLACA" },
          { columna: "estatus", label:"ACTIVO" },
          { columna: "idUnidadTipo.nombre", label:"TIPO" },
          { columna: "idUnidadMarca.nombre", label:"MARCA" },
          { columna: "modelo", label:"MODELO" },
          { columna: "color", label:"COLOR" },
        ]
      }
      id= "id"
      selected={setUnidad}
    />
 * @param {*} param0
 * @returns
 */
let totalFiltrados= 0;
const TablePageable =({lista, columnas, id, selected, titulo, csvname, resaltarFuncion, resaltarColor, paginacion, elementosPorPagina })=> {
  const [currentFiltro, setCurrentFiltro] = useState({columna: null, ascendente: true});
  const [item, setItem] = useState({});
  const [currentColumna, setCurrentColumna] = useState({ columna: null, label: "Todos los campos" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalByPage, setTotalByPage] = useState(10);
  const [search, setSearch] = useState("");
  useEffect(()=>{
    if( elementosPorPagina !== totalByPage ){
      setTotalByPage(elementosPorPagina);
    }
  },[elementosPorPagina]);
  const getDataToCsv=()=>{
    let csvDataTemp= []
    if(columnas.length){
      let columnasTemp= [];
      for( let c of columnas ){
        if(c.columna)
          columnasTemp.push(c.label);
      }
      csvDataTemp.push(columnasTemp);
      let listaTemp= [...lista];
      listaTemp= listaTemp.filter(palabras => filtro(palabras) ).sort((a,b)=> ordenar(a,b));
      for( let i of listaTemp ){
        let columnasDataTemp= [];
        for( let c of columnas ){
          if(c.columna){
            if(c.valor){
              let valorTemp= c.valor(i);
              if(valorTemp && typeof valorTemp === "string"){
                valorTemp= valorTemp.replace('"', "'");
                valorTemp= valorTemp.replace(/(\r\n|\n|\r)/gm, " ");
                valorTemp= valorTemp.replace(/(")/gm, "'");// .replace(',', '","');
              }
              columnasDataTemp.push(valorTemp);
            }else{//&& getValor(i, c.columna).includes('"')
              let valorTemp= getValor(i, c.columna);
              if(valorTemp && typeof valorTemp === "string"){
                valorTemp= valorTemp.replace('"', "'");
                valorTemp= valorTemp.replace(/(\r\n|\n|\r)/gm, " ");
                valorTemp= valorTemp.replace(/(")/gm, "'");
              }
              columnasDataTemp.push(valorTemp);
              //columnasDataTemp.push(typeof getValor(i, c.columna) === "string"? getValor(i, c.columna).replace('"', '""'): getValor(i, c.columna));
            }
          }
        }//console.log(i);
        csvDataTemp.push(columnasDataTemp);
      }
    }
    return csvDataTemp;
  }
  const getValor=(columna, nombre)=>{
    const nombreTemp= nombre? nombre.split("."): null;
    if( nombreTemp ){
      if(1 === nombreTemp.length)
        return typeof columna[nombreTemp[0]] !== "object"? columna[nombreTemp[0]] :"";
      if(2 === nombreTemp.length){
        return columna[nombreTemp[0]]? typeof columna[nombreTemp[0]][nombreTemp[1]] !== "object"? columna[nombreTemp[0]][nombreTemp[1]]: "": "";
      }
      if(3 === nombreTemp.length){
        return columna[nombreTemp[0]] ?
        columna[nombreTemp[0]][nombreTemp[1]]
            ? columna[nombreTemp[0]][nombreTemp[1]][nombreTemp[2]] !== "object"?  columna[nombreTemp[0]][nombreTemp[1]][nombreTemp[2]]: ""
          :""
        :"";
      }
    }
    return columna[nombre];
  }
  const getColumna=(registro, valor, id, splitBy, enClick, enClickDisabled, form)=>{
    let enClickDisabledTemp= enClickDisabled? enClickDisabled(registro):false;
    let formTemp= form? form(registro):null;
    //resaltarFuncion? resaltarFuncion(i)? resaltarColor: null: null
    switch (typeof valor) {
      case "string":    return (
      <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}>
        { splitBy?
          <ul>
            {valor.split(splitBy).map((v)=> <li>{v}</li>)}
          </ul>
          :form? formTemp: valor
        }
      </td> );
      case "number":    return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> { form? formTemp: valor } </td>);
      case "boolean":   return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id={"alive"+id} name={"alive"+id} checked={valor} /></div></td>);
      default: return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> { form? formTemp: valor } </td>);
    }
  }
  const filtro =(palabras = [], )=>{
    let x= search;
    if(currentColumna.columna){
      let c= {...currentColumna};
      if( !c.form || (c.form && c.valor) ){
        let y= "";
        if(c.valor){
          y= c.valor(palabras);
        }else{
          y= getValor(palabras, c.columna);
        }
        //console.log("localidad: "+ y);
        if( y === null )
          y= "";
        y= y.toString();
        /*
        if(typeof y === "number")
          y= getValor(palabras, c.columna).toString();
        if(typeof y === "boolean")
          y= getValor(palabras, c.columna).toString();*/
        if(typeof x === "boolean" && typeof y === "boolean" && y && x ){ return true }
        if(typeof y === "string" &&  y.toUpperCase().includes(x.toUpperCase())){return true }
      }
    }else{
      for (let c of columnas) {
        //let y= palabras[c.columna];
        if( !c.form || (c.form && c.valor) ){
          let y= "";
          if(c.valor){
            y= c.valor(palabras);
          }else{
            y= getValor(palabras, c.columna);
          }
          if( !y )
            y= "";
          y= y.toString();
          /*
          if(typeof y === "number")
            y= getValor(palabras, c.columna).toString();
          if(typeof y === "boolean")
            y= getValor(palabras, c.columna).toString();
          */
          /*
          //console.log("y "+typeof y+"["+c.columna+"] :"+ y);
          if( typeof y === "number" && y === Number(x) ){ return true }
          //if( typeof y === "boolean" && y && x ){ return true }
          if( typeof y === "string" && y.toUpperCase().includes(x.toUpperCase())){return true }
          */
          if(typeof x === "boolean" && typeof y === "boolean" && y && x ){ return true }
          if(typeof y === "string" &&  y.toUpperCase().includes(x.toUpperCase())){return true }
        }
      }
    }
    return false;
  }
  const ordenar =(a, b)=>{
    if( currentFiltro.columna ){
      if( currentFiltro.ascendente ){
        if (a[currentFiltro.columna] > b[currentFiltro.columna]) {
          return 1;
        }
        if (a[currentFiltro.columna] < b[currentFiltro.columna]) {
          return -1;
        }
      }else{
        if (a[currentFiltro.columna] < b[currentFiltro.columna]) {
          return 1;
        }
        if (a[currentFiltro.columna] > b[currentFiltro.columna]) {
          return -1;
        }
      }
    }
    return 0;
  }
  const getPaginas=()=>{
    let paginasTemp= [];
    const totalPaginas= Math.ceil((Math.ceil(totalFiltrados)/totalByPage));
    for(let p=currentPage; p <= totalPaginas; p++){
      paginasTemp.push(
        <li className={"page-item"+(p===currentPage?" active":"")}>
          <a className="page-link"
          onClick={()=>{ if( currentPage< totalPaginas ){setCurrentPage(p)}}}>{p}</a>
        </li>);
      if(paginasTemp.length === 5)
        break;
    }
    return paginasTemp;
  }
  const getLista=()=>{
    let listaTemp= [...lista];
    if(listaTemp.length){
      listaTemp= listaTemp.filter(palabras => filtro(palabras) ).sort((a,b)=> ordenar(a,b));
      totalFiltrados= listaTemp.length;
      if(paginacion)
        listaTemp= listaTemp.slice((currentPage*totalByPage)-(totalByPage), (currentPage*totalByPage));
    }
    return listaTemp;
  }
  return (
    <div className="container-fluid p-3" style={{ height: "100%", overflowY: "scroll"}}>
      <div className= "row" style={{ height: "80px"}}>
        <div className="col-9">{ titulo }</div>
        <div className="col-3">
          <div>
            <div className="row">
              <div className="col-1">
                <label><i class="fa fa-search"></i></label>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  className="input-field form-control"
                  style={{ outline: "none" }}
                  id="search"
                  value={ search }
                  onChange={(e)=> {
                    setSearch(e.target.value);
                    if( currentPage !== 1){
                      setCurrentPage(1);
                    }
                    }}
                  />
                  <label
                    data-toggle="tooltip"
                    data-placement="top"
                    title={"Buscar en "+currentColumna.label}>
                    <small>{ currentColumna.columna ? currentColumna.label: "Todos los campo"}</small>
                  </label>
                  <i className="fa fa-close text-danger ml-2"
                    onClick={()=> setCurrentColumna({ columna: null, label: "Todos los campo" }) }
                    hidden={!currentColumna.columna}>

                  </i>
              </div>
              { csvname &&
                <div className="col-1">
                  <CSVLink
                    data={getDataToCsv()}
                    filename = { (csvname? csvname:"data")+"-"+timeToDateNumbers(new Date().getTime())+".csv" }
                  >
                    <i className="fa fa-file-excel-o text-success" style={{"font-size": "24px"}}> csv</i>
                  </CSVLink>
                  {/*<CSVDownload data={getDataToCsv()} target="_blank" />*/}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div class="col-12 panel-resizable" style={{ height: "20%" }}>{/** height: "40vh" */}
          <table class="table table-sm">
            <thead>
              <tr>
                { columnas.map((c)=>
                  <th scope="col" key={c[id]}>
                    <span
                      onClick={()=>{ console.log("click ...");
                        if(currentFiltro.columna === c.columna){
                          setCurrentFiltro({...currentFiltro, ascendente: !currentFiltro.ascendente});
                        }
                      }}
                      onDoubleClick={()=>{//alert(c.columna);
                        if( currentFiltro.columna && currentFiltro.columna === c.columna){
                          setCurrentFiltro({columna: null, ascendente: true});
                        }else{
                          setCurrentFiltro({columna: c.columna, ascendente: true});
                        }
                      }}
                    >
                      { currentFiltro.columna && currentFiltro.columna === c.columna ?
                        <i className={"fa fa-sort-"+(currentFiltro.ascendente?"up":"down")}></i>
                        :
                          <i className={"fa fa-sort"}></i>
                      }
                    </span>
                    <span className={ "ml-1 "+(currentColumna.columna === c.columna? "text-primary": "") }
                      onClick={()=>{
                      if(currentColumna.columna !== c.columna)
                        setCurrentColumna(c);
                    }}>
                      {c.label}
                    </span>
                  </th>
                ) }
              </tr>
            </thead>
            <tbody>
              {lista?
                getLista().map((i) =>
                  <tr
                    style={
                      {
                        "background-color":
                        item && item[id] === i[id]
                            ? "rgba(29, 196, 247, 0.13)"
                            : resaltarFuncion? resaltarFuncion(i)? (typeof resaltarColor === "function"? resaltarColor(i): resaltarColor): null: null
                      }}
                    onClick={()=>{ if(selected){ selected(i); } setItem(i)}}
                  >
                    { columnas.map((c)=> <td> { getColumna(i, getValor(i, c.columna), item[id], c.splitBy, c.enClick, c.enClickDisabled, c.form) } </td> ) }
                  </tr>
                ):"Esperando contenido ..!"
              }
            </tbody>
          </table>
        </div>
        <div hidden= {!paginacion}>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={"page-item"+(currentPage <= 1? " disabled": "")}>
                <a class="page-link"
                  onClick={()=>{ if (currentPage > 1){ setCurrentPage(currentPage-1)} }}
                  aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              {getPaginas()}
              <li className={"page-item"+(currentPage >= Math.ceil((Math.ceil(lista.length)/totalByPage))? " disabled": "")}>
                <a className="page-link"
                  onClick={()=>{ if (currentPage < Math.ceil((Math.ceil(lista.length)/totalByPage))){ setCurrentPage(currentPage+1)} }}
                  aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
              <li className="ml-3">

              </li>
              <li>
                <input
                  type="number"
                  class="page-link"
                  //className="form-control"
                  style={{width: "60px"}}
                  id="search"
                  value={ totalByPage }
                  onChange={(e)=>{
                    setTotalByPage(e.target.value);
                    if( currentPage !== 1){
                      setCurrentPage(1);
                    }
                  }}
                  />
              </li>
              <li className="ml-2">
                <h2>por pagina </h2>
              </li>
            </ul>
            <ul class="pagination">
              <li>
                <h2 className="ml-3"> Pagina {currentPage}/{ Math.ceil((Math.ceil(totalFiltrados)/totalByPage)) } </h2>
              </li>
              <li>
                <h2 className="ml-3"> Total de elementos {totalFiltrados} de { Math.ceil(lista.length) } </h2>
              </li>
            </ul>
          </nav>
          {/*
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>*/}
        </div>
      </div>
    </div>
  );
}
export default TablePageable;
