import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { timingSafeEqual } from "crypto";
class  MonitoreoMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
                      id: "",
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
                  MonitoreoMenu:[],
                  search:""
                 };
                               this.MonitoreoMenu= [];
                          this.plataformasObject= [];
                         this.checarMonitoreoMenu= 0;      //temporal render
                   this.idplataformasMonitoreoMenu= 0; //temporal render
              this.plataformasMonitoreoMenuObject= [];
                          this.MonitoreoMenuArray= [];
  }



  componentDidMount =() => {
    console.log("entro")
    if(this.state.accion !== 'nuevo'){
      console.log("first")
      this.setState({id: this.props.elementos.id});
      this.plataformasObject= this.props.elementos;
      this.getfuntion("get", "", "", "plataformasmovimientosMenu/plataforma/"+this.props.elementos.id,"MonitoreoMenu")
    }else{}
  }
getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
  Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "MonitoreoMenu"){ this.MonitoreoMenu= returnVal; this.setState({ MonitoreoMenu: returnVal }); }
          }
          break;
        case "put":
          {
           if (stateVar === "MonitoreoMenu"){

            let index = this.state.MonitoreoMenu.findIndex((mov)=> mov.id == returnVal.id)

            if(index >= 0){
              this.MonitoreoMenu[index].conGps =  returnVal.conGps;
              this.MonitoreoMenu[index].sinGps =  returnVal.sinGps;
              this.MonitoreoMenu[index].todos = returnVal.todos;
            }

            this.setState({MonitoreoMenu: this.MonitoreoMenu});
            
            }
          }
          break;
        default:
          break;
      }
    }).catch(err =>{
      if( err.response ){
        console.log(err.response);
      }else{
        /**
         * Error
         */
        console.log("Error desconocido ....");
        console.log(err)
      }
  })
}

  changeMonitoreoMenu =(MonitoreoMenuIndex, value,tipo) => {

    console.log(MonitoreoMenuIndex,value,tipo)

      let menuSeleccionado =  this.MonitoreoMenu.filter(word => word.idMovimientoTipo.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[MonitoreoMenuIndex];
      this.plataformasMonitoreoMenuObject={
        "idPlataforma": this.plataformasObject,
        "id": menuSeleccionado.id,
        "idMovimientoTipo":menuSeleccionado.idMovimientoTipo,
        "conGps":menuSeleccionado.conGps,
        "sinGps":menuSeleccionado.sinGps,
        "todos":menuSeleccionado.todos
      }

      switch (tipo) {
        case 1:
          this.plataformasMonitoreoMenuObject.conGps = value;
          break;
        case 2:
          this.plataformasMonitoreoMenuObject.sinGps = value;
          break;
        case 3:
          this.plataformasMonitoreoMenuObject.todos = value;
          break;
      
      }
     
      this.getfuntion("put",this.plataformasMonitoreoMenuObject, "", "plataformasmovimientosMenu", "MonitoreoMenu");
    }
   
  render(){
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
    
        { this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Tipo Movimiento"
                  isCollapsible={false}
                  //isClosable
                  isCollapsed={ false}
                  options={ <Form.Group>
                              <Form.Input
                                className="mb-3"
                                icon="search"
                                placeholder="Buscar Tipo movimiento..."
                                position="append"
                                onChange={this.searching}
                              />
                            </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre Movimiento</Table.ColHeader>
                            <Table.ColHeader>Con gps</Table.ColHeader>
                            <Table.ColHeader>Sin gps</Table.ColHeader>
                            <Table.ColHeader>Todos(Con o sin Gps)</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            
                            {
                              this.state.MonitoreoMenu.filter(word => word.idMovimientoTipo.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento["id"] }</Table.Col>
                                  <Table.Col>{ elemento["idMovimientoTipo"].nombre }</Table.Col>
                                  
                                  <Table.Col><Form.Switch type="Checkbox" name="con_gps" 
                                                          checked={ elemento["conGps"]}
                                                         
                                                          onChange={ (e) => this.changeMonitoreoMenu(index, e.target.checked,1) } /></Table.Col>
                                  <Table.Col><Form.Switch type="Checkbox" name="sinGps" 
                                                          checked={ elemento["sinGps"]}
                                                         
                                                          onChange={ (e) => this.changeMonitoreoMenu(index, e.target.checked,2) } /></Table.Col>
                                  <Table.Col><Form.Switch type="Checkbox" name="todos" 
                                                          checked={ elemento["todos"]}
                                                         
                                                          onChange={ (e) => this.changeMonitoreoMenu(index,e.target.checked,3) } /></Table.Col>
                              
                              </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
        :null}
      </div>
    )
  }
}

export default MonitoreoMenu
