import React, { useState, useEffect } from "react";
import SiteWrapper from "../../../SiteWrapper.react";
import Select from "react-select";

import { MenuReportesGenerales } from "./MenuReportesGenerales.react";
import { ContentReportesGenerales } from "./ContentReportesGenerales.react";
import { Paginator } from "./Paginator.react";
import { ExportarReportesGenerales } from "./ExportarReportesGenerales.react";
import { LoadingScreen } from "../../../components/LoadingScreen";

const ReportesGeneralesPage = () => {
	//Variables check loading y error
	const [loading, setLoading] = useState([]);
	const [error, setError] = useState([]);
	const [data, setData] = useState();
	const [opciones, setOpciones] = useState();
	const [sinColumnas, setSinColumnas] = useState([]);

	return (
		<SiteWrapper>
			<div className="container">
				<MenuReportesGenerales
					setLoading={setLoading}
					setError={setError}
					setData={setData}
					setOpciones={setOpciones}
					setSinColumnas={setSinColumnas}
				/>
				{data && data.content && data.content.length > 0 ? (//{data !== undefined && data.content.length > 0 ? (
					<ExportarReportesGenerales
						total={data.totalElements}
						opciones={opciones}
						sinColumnas={sinColumnas}
						setLoading={setLoading}
						setError={setError}
					/>
				) : null}
				<ContentReportesGenerales
					data={data}
					sinColumnas={sinColumnas}
				/>
				{data && data.content && data.content.length > 0 ? (
					<Paginator
						data={data}
						setData={setData}
						setLoading={setLoading}
						setError={setError}
						opciones={opciones}
					/>
				) : null}
				{loading ? <LoadingScreen loading={loading} /> : null}
			</div>
		</SiteWrapper>
	);
};

export default ReportesGeneralesPage;
