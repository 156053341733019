import React, { useState, useEffect } from "react";
import classes from "./Dash.module.css";
import { Modal, Button } from 'react-bootstrap'; // Importa Modal y Button desde React-Bootstrap
import { Crud_Catalogos } from "../herramientas/Crud_Catalogos";

const Dash = (props) => {
  const value = ((100 / props.total) * props.cantidad).toFixed(3) + "%";
  
  // Estados para manejar el modal y los datos
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = () => {
    console.log(`Hiciste clic en Dash: ${props.fechaInicio}`);
    console.log(`Hiciste clic en Dash: ${props.fechaFin}`);

    if (props.alcance == 2766) {
      // Crud_Catalogos("informes/contador/movimientosGeneralNavieroMovimientos/" + JSON.parse(sessionStorage.getItem("currentUser"))[0].usuario.id + "/" + props.fechaInicio + "/" + props.fechaFin + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
      //   .then((returnVal) => {
      //     const valoresNumericos = returnVal.map(item => item.id_movimiento+"---"+item.economicor+"---"+item.economicot);
      //     setModalContent(`<ul>${valoresNumericos.map(num => `<li>${num}</li>`).join('')}</ul>`); // Formato HTML para la lista
      //     handleShow(); // Muestra el modal
      //   })

      Crud_Catalogos("informes/contador/movimientosGeneralNavieroMovimientos/" + JSON.parse(sessionStorage.getItem("currentUser"))[0].usuario.id + "/" + props.fechaInicio + "/" + props.fechaFin + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
  .then((returnVal) => {
    // Crear una tabla en lugar de una lista
    const tableRows = returnVal.map(item => `
      <tr>
        <td>${item.id_movimiento}</td>
        <td>${item.economicor}</td>
        <td>${item.economicot}</td>
      </tr>`).join('');

    // Construir la tabla completa
    const tableHTML = `
      <table border="1" style="width:100%; text-align:left;">
        <thead>
          <tr>
            <th>ID Movimiento</th>
            <th>Economico R</th>
            <th>Economico T</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

    setModalContent(tableHTML); // Asignar el contenido de la tabla al modal
    handleShow(); // Muestra el modal
  })
  .catch((err) => {
    if (err.response) {
      console.log(err.response);
    } else {
      console.log("Error desconocido ...." + err);
    }
  })        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log("Error desconocido ...." + err);
          }
        });
    } else if (props.alcance == 2765) {
      // Crud_Catalogos("informes/contador/movimientosTransportistaNavieroMovimientos/" + props.fechaInicio + "/" + props.fechaFin + "/" + props.plataforma + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
      //   .then((returnVal) => {
      //     const valoresNumericos = returnVal.map(item => item.id_movimiento);
      //     setModalContent(`<ul>${valoresNumericos.map(num => `<li>${num}</li>`).join('')}</ul>`);
      //     handleShow();
      //   })
      Crud_Catalogos("informes/contador/movimientosTransportistaNavieroMovimientos/" + props.fechaInicio + "/" + props.fechaFin + "/" + props.plataforma + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
      .then((returnVal) => {
        // Crear las filas de la tabla con solo el id_movimiento
        const tableRows = returnVal.map(item => `
          <tr>
            <td>${item.id_movimiento}</td>
            <td>${item.economicor}</td>
            <td>${item.economicot}</td>
          </tr>`).join('');
    
        // Construir la tabla completa
        const tableHTML = `
          <table border="1" style="width:100%; text-align:left;">
            <thead>
              <tr>
                <th>ID Movimiento</th>
                <th>Economico Remol.</th>
                <th>Economico T</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
        `;
    
        setModalContent(tableHTML); // Asignar el contenido de la tabla al modal
        handleShow(); // Muestra el modal
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ...." + err);
        }
      })
    


        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log("Error desconocido ...." + err);
          }
        });
    } else if (props.alcance == 2771) {
      // Crud_Catalogos("informes/contador/alertasTransportistaNavieroDetalle/" + props.fechaInicio + "/" + props.fechaFin + "/" + props.plataforma + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
      //   .then((returnVal) => {
      //     const valoresNumericos = returnVal.map(item => item.economicot);
      //     setModalContent(`<ul>${valoresNumericos.map(num => `<li>${num}</li>`).join('')}</ul>`);
      //     handleShow();
      //   })
      Crud_Catalogos("informes/contador/alertasTransportistaNavieroDetalle/" + props.fechaInicio + "/" + props.fechaFin + "/" + props.plataforma + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
  .then((returnVal) => {
    // Crear las filas de la tabla con los valores de economicot
    const tableRows = returnVal.map(item => `
      <tr>
        <td>${item.id_movimiento}</td>
        <td>${item.sello}</td>
        <td>${item.economicot}</td>
        <td>${item.economicor}</td>
        <td>${item.contenedor}</td>
        
      </tr>`).join('');

    // Construir la tabla completa
    const tableHTML = `
      <table border="1" style="width:100%; text-align:left;">
        <thead>
          <tr>
            <th>Id Movimeinto</th>
            <th>Sello</th>
            <th>Económico T</th>
            <th>Económico R</th>
            <th>Contenedor</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

    setModalContent(tableHTML); // Asignar el contenido de la tabla al modal
    handleShow(); // Muestra el modal
  })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log("Error desconocido ...." + err);
          }
        });
    } else if (props.alcance == 2772) {
      // Crud_Catalogos("informes/contador/alertasGeneralNavieroDetalle/12354/" + props.fechaInicio + "/" + props.fechaFin + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
      //   .then((returnVal) => {
      //     const valoresNumericos = returnVal.map(item => item.economicot);
      //     setModalContent(`<ul>${valoresNumericos.map(num => `<li>${num}</li>`).join('')}</ul>`);
      //     handleShow();
      //   })
      Crud_Catalogos("informes/contador/alertasGeneralNavieroDetalle/"+JSON.parse(sessionStorage.getItem("currentUser"))[0].usuario.id +"/"+ props.fechaInicio + "/" + props.fechaFin + "/" + props.texto, "dashboardNaviera", "get", "", "", "", "movimientos", "", [])
  .then((returnVal) => {
    // Crear las filas de la tabla con los valores de economicot
    const tableRows = returnVal.map(item => `
      <tr>
        <td>${item.id_movimiento}</td>
        <td>${item.sello}</td>
        <td>${item.economicot}</td>
        <td>${item.economicor}</td>
        <td>${item.contenedor}</td>

      </tr>`).join('');

    // Construir la tabla completa
    const tableHTML = `
      <table border="1" style="width:100%; text-align:left;">
        <thead>
          <tr>
            <th>Id Movimiento</th>
            <th>Sello</th>
            <th>Económico T</th>
            <th>Económico R</th>
            <th>Contenedor</th>

          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

    setModalContent(tableHTML); // Asignar el contenido de la tabla al modal
    handleShow(); // Muestra el modal
  })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log("Error desconocido ...." + err);
          }
        });
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={`${classes.card} col-lg-3 col-6 mt-2`}
        style={{
          backgroundColor: props.color ? props.color : "rgba(88, 208, 186, 0.3)",
        }}
      >
        <div className="row">
          {props.showBar && (
            <div className="col-6">
              <div className="ml-2">
                <h3 className={classes.textWhite}>{props.cantidad}</h3>
                <h5 className={classes.textWhite}>{props.texto}</h5>
                <h5 className={classes.textWhite}>{props.exportaciones}</h5>
                <h5 className={classes.textWhite}>{props.importaciones}</h5>
                <h5 className={classes.textWhite}>{props.conAdmin}</h5>
                <h5 className={classes.textWhite}>{props.sinAdmin}</h5>
                <h5 className={classes.textWhite}>{props.sinMod}</h5>
                <h5 className={classes.textWhite}>{props.bajoProtocolo}</h5>
                <h5 className={classes.textWhite}>{props.bajoProtocoloMabe}</h5>

                <h5 className={classes.textWhite}>{props.cdr_monterrey}</h5>
		            <h5 className={classes.textWhite}>{props.cdr_guadalajara}</h5>
		            <h5 className={classes.textWhite}>{props.cdr_huehuetoca}</h5>
		            <h5 className={classes.textWhite}>{props.cdr_amerisa_queretaro}</h5>
		            <h5 className={classes.textWhite}>{props.planta_slp}</h5>
		            <h5 className={classes.textWhite}>{props.planta_mcm_slp}</h5>
		            <h5 className={classes.textWhite}>{props.planta_celaya}</h5>
		            <h5 className={classes.textWhite}>{props.planta_saltillo}</h5>
		            <h5 className={classes.textWhite}>{props.planta_queretaro}</h5>
		            <h5 className={classes.textWhite}>{props.planta_queretato_typ}</h5>
		            <h5 className={classes.textWhite}>{props.planta_queretato_mpt}</h5>
		            <h5 className={classes.textWhite}>{props.faber_slp}</h5>
		            <h5 className={classes.textWhite}>{props.koblenz_cuahutitlan}</h5>
		            <h5 className={classes.textWhite}>{props.puerto_manzanillo}</h5>
		            <h5 className={classes.textWhite}>{props.puerto}</h5>
		            <h5 className={classes.textWhite}>{props.patio_linea_transportista}</h5>
		            <h5 className={classes.textWhite}>{props.cliente_externo}</h5>
		            <h5 className={classes.textWhite}>{props.cdr_kaisen_queretaro}</h5>

              </div>
            </div>
          )}
          {!props.showBar && (
            <div>
              <div className="col-3">
                <div className="ml-2">
                  <h3>{props.instalados}</h3>
                  <h5>{"Instalados"}</h5>
                </div>
              </div>
              <div className="col-3">
                <div className="ml-2">
                  <h3>{props.recuperar}</h3>
                  <h5>{"Por recuperar"}</h5>
                </div>
              </div>
            </div>
          )}
          <span className="icon col-6">
            <i className="fa fa-bar-chart float-right mt-2 mr-2" style={{ fontSize: "60px" }}></i>
          </span>
        </div>

        <div className="small-box-footer">
          {!props.showBar && <p>{props.texto}</p>}
          {props.showBar && (
            <div className="progress">
              <div
                className="progress-bar text-dark"
                role="progressbar"
                style={{ width: value }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <strong className={classes.textWhite}>{value}</strong>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Resultados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Aquí se muestra el contenido dinámico en HTML */}
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dash;
