import React, { useState, useEffect } from 'react';
import { Grid, Card,Form, Table } from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { Doughnut } from 'react-chartjs-2';
import Select from 'react-select';

export const TableroCapturaEnTiempo = () => {
  const [acv, setAcv] = useState([]);
  const [url, setUrl] = useState("");
  const [plataformas, setPlataformas] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [mostrarFecha, setMostrarFecha] = useState(false);
  const [datos, setDatos] = useState([]);
  const [sinDatos, setSinDatos] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(false);
  const [fechaFin, setFechaFin] = useState(false);
  

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    setFechaInicio(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);
    setFechaFin(`${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`);


    const fetchPlataformas = async () => {
      try {
        const plataformas1 = await getfuntion("get", [], "", "plataformas/plataforma/1", "");
        const plataformas3 = await getfuntion("get", [], "", "plataformas/plataforma/3", "");
        setPlataformas([...plataformas1, ...plataformas3]);
      } catch (error) {
        console.error("Error fetching plataformas:", error);
      }
    };
    fetchPlataformas();
  }, []);


  useEffect(() => {
    if (mostrarFecha) {
      const fechaInicioInput = document.getElementById("fechaInicio");
      const fechaFinInput = document.getElementById("fechaFin");
       setFechaInicio(fechaInicioInput?.value);
       setFechaFin(fechaFinInput?.value);
    } else {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      setFechaInicio(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);
      setFechaFin(`${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`);
    }

  }, mostrarFecha);


  const changeMostrarFecha = () => {
    setMostrarFecha(!mostrarFecha);

    if (!mostrarFecha) {
      const fechaInicioInput = document.getElementById("fechaInicio");
      const fechaFinInput = document.getElementById("fechaFin");
       setFechaInicio(fechaInicioInput?.value);
       setFechaFin(fechaFinInput?.value);
    } else {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      setFechaInicio(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);
      setFechaFin(`${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`);
    }


  }

  const getfuntion = async (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        if (metodo === "get") return returnVal;
        return null;
      });
  };

  const options = plataformas.map((plataforma) => ({
    value: plataforma.id,
    label: plataforma.nombre,
  }));

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleButtonClick = async () => {
     const selectedIds = selectedOptions.map(option => option.value);
     const newUrl = `informes/capturaEnTiempo/${fechaInicio}/${fechaFin}/${selectedIds.join(",")}`; 
     setUrl(newUrl);
    setDatos([]);
    setAcv([]);
    setSinDatos(false); // Resetear mensaje antes de la nueva consulta

    const returnVal = await getfuntion("get", [], "", newUrl, "");

    if (!Array.isArray(returnVal) || returnVal.length === 0) {
      setSinDatos(true); // No hay datos
      return;
    }

    setAcv([...returnVal]);
    setDatos(
      returnVal.map((usr) => ({
        datasets: [{
          backgroundColor: ["#83ce83", "#f96a5d", "#6800B4", "#00A6B4"],
          data: [usr.on_time, usr.middle_time, usr.out_time]
        }]
      }))
    );
  };

  return (
    <SiteWrapper>
      <div className="container">
        <h1>Tablero de captura en tiempo:</h1>
        <div>
          Tiempo Actual
        <Form.Switch
                    type="Checkbox"
                    name="toggle"
                     value={mostrarFecha}
                    checked={mostrarFecha}
                    onChange={changeMostrarFecha}
                  /> Histórico
	{mostrarFecha?<div>
            Fecha Inicio<input
						onChange={(opcion) => {
							setFechaInicio(opcion.target.value)
						}}
						type="date"
						id="fechaInicio"
						name="fechaInicio"
					></input>
         Fecha Fin <input
						onChange={(opcion) => {
							setFechaFin(opcion.target.value)
						}}
						type="date"
						id="fechaFin"
						name="fechaFin"
					></input>
          </div>


          :null}

          <Select
            isMulti
            options={options}
            hideSelectedOptions
            placeholder="Selecciona la plataforma"
            onChange={handleSelectChange}
          />
          <button 
            onClick={handleButtonClick} 
            className="mt-4 p-2 bg-blue-500 text-white rounded-lg shadow">
            Mostrar Selección
          </button>
        </div>
        <Grid.Col sm={12} lg={12}>
          <Card>
            <div className="col col-12 col-sm-12">
              {sinDatos ? (
                <p style={{ textAlign: "center", fontSize: "18px", color: "red" }}>
                  La plataforma no tiene datos
                </p>
              ) : (
                <>
                  {acv.map((usr, index) => (
                    <div key={index} className="container mt-3" style={{ width: '33%', float: 'left' }}>
                      {usr.username}
                      <Doughnut data={datos[index]} />
                    </div>
                  ))}
                  <Table cards striped responsive className="table-vcenter">
                    <Table.Header align="center">
                      <Table.ColHeader>Id Usuario</Table.ColHeader>
                      <Table.ColHeader>Nombre de usuario</Table.ColHeader>
                      <Table.ColHeader>0-20 min</Table.ColHeader>
                      <Table.ColHeader>20-40 min</Table.ColHeader>
                      <Table.ColHeader>&gt;40min</Table.ColHeader>
                      <Table.ColHeader>Porcentaje</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {acv.map((usr, index) => (
                        <Table.Row key={index} align="center">
                          <Table.Col>{usr.id_usuario}</Table.Col>
                          <Table.Col>{usr.username}</Table.Col>
                          <Table.Col>{usr.on_time}</Table.Col>
                          <Table.Col>{usr.middle_time}</Table.Col>
                          <Table.Col>{usr.out_time}</Table.Col>
                          <Table.Col>{((100 * usr.on_time) / (usr.on_time + usr.middle_time + usr.out_time)).toFixed(0)}%</Table.Col>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </>
              )}
            </div>
          </Card>
        </Grid.Col>
      </div>
    </SiteWrapper>
  );
};

export default TableroCapturaEnTiempo;
