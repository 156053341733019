import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
/**
 * Date tools
 */

class EntregaForm extends Component {
  constructor(props) {
    super(props);
    //console.log('props', props.stor.obj.plataforma);
    this.state = {
      idEntrega: 0,
      idDestino: { value: 1, label: "DESTINO SIN CATALOGO", indice: 0 },
      orden: 1,
      destino: "",
      direccion: "",
      contacto: "",
      cartaPorte: "",
      ciudad: "",
      fechaArribo: "",
      fechaSalida: "",
      tiempoEstadia: "",
      tiempoTraslado: "",
      fechaCambio: "",
      citaCarga: "",
      inicioRuta: "",
      estatus: 0,
      eta: 0,
      latitud: 0,
      longitud: 0,
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      disabledSave: true,
      ot: "",
    };
    this.valid = { destino: false, orden: false }; //, direccion: false };
    this.idDestinos = [];
    this.entrega = {
      idEntrega: 0,
      idMovimiento: this.props.idmovimiento,
      idDestino: 1, //{ value: 1, label: "DESTINO SIN CATALOGO", indice:0 },
      orden: 0,
      destino: "PENDIENTE",
      direccion: "PENDIENTE",
      contacto: "PENDIENTE",
      cartaPorte: "",
      fechaArribo: null,
      fechaSalida: null,
      tiempoEstadia: null,
      tiempoTraslado: null,
      fechaAlta: null,
      fechaCambio: null,
      latitud: 0,
      longitud: 0,
      citaCarga: null,
      inicioRuta: null,
      estatus: 0,
      eta: 0,
      ot:"",
    };
  }

  componentDidMount = () => {
    this.idDestinos.push({
      value: 1,
      label: "DESTINO SIN CATALOGO",
      indice: 0,
    });
    if (this.props.destinos) {
      this.props.destinos.map((destino, index) => {
        this.idDestinos.push({
          value: destino.id,
          label: destino.nombre,
          indice: index,
        });
        if (this.entrega) {
          if (this.entrega.idDestino === 1) {
            this.setState({
              idDestino: { value: 1, label: "DESTINO SIN CATALOGO", indice: 0 },
            });
          }
          if (destino.id === this.entrega.idDestino) {
            this.setState({
              idDestino: {
                value: destino.id,
                label: destino.nombre,
                indice: index,
              },
            });
          }
        }
      });
    }

    if (this.state.accion === "nuevo") {
      this.setState({ direccion: "PENDIENTE" });
      this.setState({ contacto: "PENDIENTE" });
      this.setState({ destino: "PENDIENTE" });
      this.setState({ latitud: 0 });
      this.setState({ longitud: 0 });
    } else {
      //Se Modifica Registro Actual
      this.entrega = this.props.elementos;
      this.valid = {
        idDestino: true,
        destino: true,
        orden: true,
        direccion: true,
      };
      //this.setState({idDestino      : { value: 1, label: "DESTINO SIN CATALOGO", indice:0 } }); //this.props.elementos.idDestino      });
      this.setState({ idEntrega: this.props.elementos.idEntrega });
      this.setState({ orden: this.props.elementos.orden });
      this.setState({ destino: this.props.elementos.destino });
      this.setState({ ciudad: this.props.elementos.ciudad });

      this.setState({ direccion: this.props.elementos.direccion });
      this.setState({ contacto: this.entrega.contacto });
      this.setState({ cartaPorte: this.entrega.cartaPorte });
      this.setState({ ot: this.entrega.ot });
      this.setState({ latitud: this.props.elementos.latitud });
      this.setState({ longitud: this.props.elementos.longitud });
      this.setState({
        fechaArribo: this.props.elementos.fechaArribo
          ? new Date(this.props.elementos.fechaArribo)
          : "",
      });
      this.setState({
        fechaSalida: this.props.elementos.fechaSalida
          ? new Date(this.props.elementos.fechaSalida)
          : "",
      });
      this.setState({
        tiempoEstadia: this.props.elementos.tiempoEstadia
          ? new Date(this.props.elementos.tiempoEstadia)
          : "",
      });
      this.setState({
        tiempoTraslado: this.props.elementos.tiempoTraslado
          ? new Date(this.props.elementos.tiempoTraslado)
          : "",
      });
      this.setState({
        fechaCambio: this.props.elementos.fechaCambio
          ? new Date(this.props.elementos.fechaCambio)
          : "",
      });
      this.setState({
        citaCarga: this.props.elementos.citaCarga
          ? new Date(this.props.elementos.citaCarga)
          : "",
      });
      this.setState({
        inicioRuta: this.props.elementos.inicioRuta
          ? new Date(this.props.elementos.inicioRuta)
          : "",
      });
      this.setState({ estatus: this.props.elementos.estatus });
    }
  };
  isValid = (campo) => {
    switch (campo) {
      //case "idDestino": return this.valid.idDestino;
      //  break;
      case "destino":
        return this.valid.destino;
        break;
      case "orden":
        return this.valid.orden;
        break;
      //case "direccion": return this.valid.direccion;
      //  break;
      default:
        break;
    }
  };
  disableSave = () => {
    //console.log("122 this.state.idDestino");
    //console.log(this.state.idDestino);
    if (this.state.idDestino.value === 1) {
      if (this.state.destino.lenght > 3) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.state.contacto.lenght > 3) {
        return true;
      } else {
        return false;
      }
    }
    //let band= false;
    //Object.keys(this.valid).forEach(key =>
    //  {
    //  if( !this.valid[key]){
    //    band=true; }
    //  });
    //if(band){ return true }else{ return false }
  };

  delete() {
    this.props.getfuntion(
      "delete",
      "",
      this.props.elementos.idEntrega,
      "entregas",
      "entregas"
    );
  }
  crud_put() {
    let tmp,
      tmp2 = "";
    tmp = this.entrega.destino;
    tmp2 = this.entrega.direccion;
    this.entrega.destino = tmp.trim();
    this.entrega.direccion = tmp2.trim();
    //console.log("156 this.entrega");
    //console.log(this.entrega);
    this.props.getfuntion("put", this.entrega, "", "entregas", "entregas");
  }
  crud_post() {
    this.props.getfuntion("post", this.entrega, "", "entregas", "entregas");
  }

  changeidDestinos = (event) => {
    if (this.props.destinos) {
      //console.log("154 this.props.destinos[event.indice]");
      //console.log(this.props.destinos[event.indice]);
      this.entrega.idDestino = this.props.destinos[event.indice].id;
      this.entrega.destino = this.props.destinos[event.indice].nombre;
      this.entrega.latitud = this.props.destinos[event.indice].latitud;
      this.entrega.longitud = this.props.destinos[event.indice].longitud;
      this.entrega.direccion = this.props.destinos[event.indice].direccion;
      this.entrega.contacto = "PENDIENTE";

      this.setState({ idDestino: event });
      this.setState({ destino: this.props.destinos[event.indice].nombre });
      this.setState({ direccion: this.props.destinos[event.indice].direccion });
      this.setState({ latitud: this.props.destinos[event.indice].latitud });
      this.setState({ longitud: this.props.destinos[event.indice].longitud });
      this.setState({ contacto: "PENDIENTE" });
    }

    //if(this.entrega.direccion.length > 5 ){this.valid.direccion= true;}else{this.valid.direccion= false;}
    //if(this.entrega.destino.length > 5 )  {this.valid.destino= true;  }else{this.valid.destino= false;  }
    //if(event.value > 0 ){  this.valid.idDestino= true; }else{  this.valid.idDestino= false;  }
    if (event.value === 1) {
    } else {
      //console.log("151 this.entrega");
      //console.log(this.entrega);
    }
  };

  changeDestino = (event) => {
    this.entrega.destino = event.target.value;
    this.setState({ destino: event.target.value });
    if (event.target.value.length > 5) {
      this.valid.destino = true;
    } else {
      this.valid.destino = false;
    }
  };
  changeCiudad = (event) => {
    this.entrega.ciudad = event.target.value;
    this.setState({ ciudad: event.target.value });
    if (event.target.value.length > 2) {
      this.valid.ciudad = true;
    } else {
      this.valid.ciudad = false;
    }
  };
  changeCartaPorte = (event) => {
    this.entrega.cartaPorte = event.target.value;
    this.setState({ cartaPorte: event.target.value });
    if (event.target.value.length > 3) {
      this.valid.cartaPorte = true;
    } else {
      this.valid.cartaPorte = false;
    }
  };
  changeOt = (event) => {
    this.entrega.ot = event.target.value;
    this.setState({ ot: event.target.value });
    if (event.target.value.length > 3) {
      this.valid.ot = true;
    } else {
      this.valid.ot = false;
    }
  };
  changeOrden = (event) => {
    this.entrega.orden = event.target.value;
    this.setState({ orden: event.target.value });
    if (event.target.value > 0 && event.target.value <= 32767) {
      this.valid.orden = true;
    } else {
      this.valid.orden = false;
    }
  };
  changeDireccion = (event) => {
    this.entrega.direccion = event.target.value;
    this.setState({ direccion: event.target.value });
  };
  changeLatitud = (event) => {
    this.entrega.latitud = event.target.value;
    this.setState({ latitud: event.target.value });
  };
  changeLongitud = (event) => {
    this.entrega.longitud = event.target.value;
    this.setState({ longitud: event.target.value });
  };
  changeContacto = (event) => {
    this.entrega.contacto = event.target.value;
    this.setState({ contacto: event.target.value });
  };
  changeEstatus = (event) => {
    this.state.estatus === 0
      ? this.setState({ estatus: 1 })
      : this.setState({ estatus: 0 });
    this.state.estatus === 0
      ? (this.entrega.estatus = 1)
      : (this.entrega.estatus = 0);
  };
  guardarEntrega = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  render() {
    return (
      <div>
        {
          //this.props.destinos?
          <div>
            {/*console.log(this.entrega)*/}
            {this.state.accion === "modificar" ? (
              <h1> {this.props.elementos.destino} </h1>
            ) : null}
            <Grid.Row>
              <Grid.Col md={12} xl={12}>
                <Grid.Row>
                  <Grid.Col md={8} lg={8}>
                    <Form.Group label="Catalogo">
                      <Select
                        value={this.state.idDestino}
                        onChange={this.changeidDestinos}
                        options={this.idDestinos}
                        valid={
                          (this.isValid("idDestino") &&
                          this.state.destino.length > 0) ||
                          (this.isValid("destino") &&
                          this.state.idDestino.length > 0)
                        }
                        invalid={
                          !this.isValid("idDestino") &&
                          this.state.idDestino.length > 0
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <Form.Group label="Carta Porte">
                      <Form.Input
                        type="text"
                        name="Carta Porte"
                        feedback="Al menos 3"
                        value={this.state.cartaPorte}
                        placeholder="Carta Porte..."
                        onChange={this.changeCartaPorte}
                        //valid={this.isValid("cartaPorte") && this.state.cartaPorte.length > 0 }
                        //invalid={!this.isValid("orden") && this.state.cartaPorte.length > 0}
                      />
                    </Form.Group>
                  </Grid.Col>                  
                  {this.state.idDestino.value === 1 ? (
                    <Grid.Col md={8} lg={8}>
                      <Form.Group label="Nombre de la Entrega">
                        <Form.Input
                          type="text"
                          name="destino"
                          feedback="Al menos 6"
                          value={this.state.destino}
                          placeholder="Ingresa Nombre del Destino..."
                          invalid={
                            !this.isValid("destino") &&
                            this.state.destino.length > 0
                          }
                          onChange={this.changeDestino}
                        />
                      </Form.Group>
                    </Grid.Col>
                  ) : (
                    ""
                  )}
                  {this.props.stor.obj.plataforma.id === 9 ? (
                    <Grid.Col md={4} lg={4}>
                    <Form.Group label="OT">
                      <Form.Input
                        type="text"
                        name="ot"
                        feedback="Al menos 3"
                        value={this.state.ot}
                        placeholder="OT..."
                        onChange={this.changeOt}
                      />
                    </Form.Group>
                  </Grid.Col>
                  ): null}
                   <Grid.Col md={8} lg={8}>
                      <Form.Group label="Ciudad">
                        <Form.Input
                          type="text"
                          name="ciudad"
                          feedback=""
                          value={this.state.ciudad}
                          placeholder="Ingresa Nombre de la Ciudad..."
                          invalid={
                            !this.isValid("ciudad") &&
                            this.state.ciudad && this.state.ciudad.length > 0
                          }
                          onChange={this.changeCiudad}
                        />
                      </Form.Group>
                    </Grid.Col>


                  <Grid.Col md={4} lg={4}>
                    <Form.Group label="Orden">
                      <Form.Input
                        type="number"
                        name="orden"
                        feedback="Al menos 1"
                        value={this.state.orden}
                        placeholder="Ingresa orden..."
                        onChange={this.changeOrden}
                        valid={
                          this.isValid("orden") && this.state.orden.length > 0
                        }
                        invalid={
                          !this.isValid("orden") && this.state.orden.length > 0
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                  {this.state.idDestino.value === 1 ? (
                    <Grid.Col md={12} lg={12}>
                      <Form.Group label="Dirección">
                        <Form.Input
                          type="text"
                          name="direccion"
                          feedback="Al menos 6 caracteres"
                          value={this.state.direccion}
                          placeholder="Ingresa dirección..."
                          onChange={this.changeDireccion}
                        />
                      </Form.Group>
                      <Form.Group label="Contacto">
                        <Form.Input
                          type="text"
                          name="contacto"
                          value={this.state.contacto}
                          placeholder="Ingresa Contacto..."
                          onChange={this.changeContacto}
                        />
                      </Form.Group>
                    </Grid.Col>
                  ) : (
                    <Grid.Col md={12} lg={12}>
                      <Form.Group label="Contacto">
                        <Form.Input
                          type="text"
                          name="contacto"
                          value={this.state.contacto}
                          placeholder="Ingresa Contacto..."
                          onChange={this.changeContacto}
                        />
                      </Form.Group>
                    </Grid.Col>
                  )}
                  <Grid.Col md={6} lg={6}>
                    <Form.Group label="Latitud">
                      <Form.Input
                        type="number"
                        name="latitud"
                        value={this.state.latitud}
                        placeholder="Ingresa Latitud..."
                        onChange={this.changeLatitud}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} lg={6}>
                    <Form.Group label="Longitud">
                      <Form.Input
                        type="number"
                        name="longitud"
                        value={this.state.longitud}
                        placeholder="Ingresa Longitud.."
                        onChange={this.changeLongitud}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            <div className="float-right">
              <span class="badge">
                <Button
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  //disabled= { this.state.idDestino==='1'? false : true }
                  disabled={this.disableSave()}
                  onClick={this.guardarEntrega}
                >
                  <span class="badge">
                    <Icon link={true} name="save" />
                    Guardar
                  </span>
                </Button>
              </span>
              {/* {console.log("canceladaManual: " + this.props.elementos.canceladaManual)} */}
              {this.state.accion === "modificar" && this.props.elementos.canceladaManual === false ? (
                <span className="ml-2">
                  <Button
                    pill
                    size="sm"
                    outline
                    color="primary"
                    onClick={() => this.delete()}
                  >
                    <Icon name="trash" />
                  </Button>
                </span>
              ) : null}
            </div>

            <Grid.Col md={3} lg={3}>
              <Form.Group label="Est@tus">
                <Form.Switch
                  type="Checkbox"
                  name="toggle"
                  value={this.state.estatus}
                  checked={this.state.estatus}
                  onChange={this.changeEstatus}
                />
              </Form.Group>
            </Grid.Col>
          </div>
          //  :null
        }
      </div>
    );
  }
}
export default EntregaForm;
