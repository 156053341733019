import React, { useEffect, useContext, useState } from "react";
import { NotificationManager } from "react-notifications";
import Stomp from "stompjs";
import { softphoneContext } from "../context/softphoneContext";
//const url =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
//const url2 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
//
let url =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url1 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url2 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
const user = "WEBSOCKET";
const pass = "w3bs0k3t2024";
let client = Stomp.client(url);
var cat = {};
let postOrigin = false;
const PanelMensajeria =({metodo, stor, mensaje, plataformasAlertas, movimientos, movimientosT, hacerEvento, update, updateT,
  alertasNCat, elementosN, alertasTCat, elementosT, navieras, terrestres, etasN, updateEtas, topics, movPatio })=> {
  const softphone = useContext(softphoneContext);
  const [mensajeEntrante, setMensajeEntrante] = useState(null);
  useEffect(() => {
    if(topics.length){
      console.log("conectar al websocket: ");
      conectar();
    }    
  }, [topics]);
  
  const conectar = () => {    
    client = Stomp.client(url);
    client.heartbeat = {
      incoming: 20000,
      outgoing: 20000,
    };
    client.debug = () => {};
    client.reconnect_delay = 5000;
    client.connect(
      user,
      pass,
      (frame) => {
        //Conect callback       
        if (topics && topics.length) {
          for (let t of topics) {
            client.subscribe(t, (message) => { 
              //let actualizar= udate;        
              if (message.body.includes("{")) {  
                //actualizar(message.body);  
                setMensajeEntrante(message.body);
              }              
            });
          }
        }
      },
      (err) => {
        url = (url === url1) ? url2 : url1;
        //error useCallback
        console.log(err);
        if (typeof err === "string" && err.toUpperCase().includes("LOST CONNECTION")) {
          setTimeout(() => {
            conectar();
          }, 5000);
          //alert(err);
        }        
      }
    );
  }

  useEffect(() => {
    if(mensajeEntrante){
      udate(mensajeEntrante);
    }    
  }, [mensajeEntrante]);

  useEffect(()=>{
    if (
      metodo !== "get" &&
      stor.updateStatusMensaje_Object &&
      mensaje.data
    ) {
      let movimientosTemp= [];
      if (mensaje.vista == "navierasAlertas" && navieras && movimientos) {
        movimientosTemp= movimientos.filter(m => m.id === mensaje.data.idMovimiento);
        if( movimientosTemp.length === 1 ){
          sendMessage(
            "/topic/"+movimientosTemp[0].idPlataforma.id+"-"+movimientosTemp[0].idCorredor.id,
            JSON.stringify(mensaje),
            mensaje.data
          );
        }  
      }
      if (mensaje.vista == "terrestresAlertas" && terrestres && movimientosT) {
        movimientosTemp= movimientosT.filter(m => m.id === mensaje.data.idMovimiento);
        if( movimientosTemp.length === 1 ){
          sendMessage(
            "/topic/"+ movimientosTemp[0].plataforma.id+"-"+movimientosTemp[0].corredor.id,
            JSON.stringify(mensaje),
            mensaje.data
          );
        }
      }         
    }
  },[metodo, stor, mensaje]);

  const sendMessage = (topic, msg, data) => {     
    stor.updateStatusMensaje(false);
    postOrigin = true;
    //client.send(topic, msg);
    client.send(topic, {}, msg);
  };

  const actualizaAlertas = (alertCat, alertasMovimientos, mensaje, tipo) => {
    cat = plataformasAlertas ? plataformasAlertas : {};
    let movimientoTemp = {};
    const idMovimiento = mensaje.data.idMovimiento;
    tipo === "NAVIERA"
      ? movimientos.map((mov, index) => (movimientoTemp[mov.id] = mov))
      : movimientosT.map(
          (mov, index) => (movimientoTemp[mov.id] = mov)
        );
        if(  tipo === "NAVIERA" && !movimientoTemp[idMovimiento]){
          return;
        }
    let idPlataforma =
      tipo === "NAVIERA"
        ? movimientoTemp[idMovimiento]?.idPlataforma?.id
        : movimientoTemp[idMovimiento]?.plataforma?.id;
    if (mensaje.accion === "post") {
      
      //Desconocido
      if (!alertasMovimientos[idMovimiento]) {
        alertasMovimientos[idMovimiento] = {};
      }
      if (!movimientoTemp[idMovimiento]) {
        return;
      }
      //Si existe el catálogo
      
      if (cat[idPlataforma]) {
        if (cat[idPlataforma][mensaje.data.idAlertaTipo]) {
          //AQUI LA ACTUALIZACION
          if (
            !alertCat[
              cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria
                .nombre
            ]
          ) {
            alertCat[
              cat[idPlataforma][
                mensaje.data.idAlertaTipo
              ].alertaCategoria.nombre
            ] = [];
          }
          alertCat[
            cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.nombre
          ].push({
            alert: mensaje.data,
            color:
              cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria
                .color,
            logo: cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria
              .logo,
            tiempo:
              cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria
                .tiempoAtencion,
          });
          if (
            !alertasMovimientos[idMovimiento][
              cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.logo
            ]
          ) {
            alertasMovimientos[idMovimiento][
              cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.logo
            ] = [];
          }
          alertasMovimientos[idMovimiento][
            cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.logo
          ].push({
            alert: mensaje.data,
            color:
              cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria
                .color,
            tipo: cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria
              .nombre,
          });
          hacerEvento(
            cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.nombre
          );
          /*
          if (
            cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.id ===
            34
          ) {
            NotificationManager.error( 
              tipo === "NAVIERA"
                ? `Transportista: ${movimientos[idMovimiento].idTransportista.nombre}\nBooking: ${movimientos[idMovimiento].booking}`
                : `Transportista: ${movimientos[idMovimiento].transportista.nombre}\nPlaca: ${movimientos[idMovimiento].placa}`,
              `Pánico: ${idMovimiento} (Movimiento)`,
              180000
            );
          }*/
          if( cat[idPlataforma][mensaje.data.idAlertaTipo].notificacionTimeSeg ){
            let mensajeTemp= <div></div>;
            if( tipo === "NAVIERA" ){
              const movPatioTemp= movPatio[idMovimiento]? movPatio[idMovimiento] : null;
              mensajeTemp=
              <div>
                <div className="list-group-transparent">
                  <li href="" class="list-group-item list-group-item-action text-light"><h5>LT: {movimientoTemp[idMovimiento].idTransportista.nombre}</h5></li>
                  <li href="" class="list-group-item list-group-item-action text-light"><h5>BOOKING: {movimientoTemp[idMovimiento].booking}</h5></li>
                  <li href="" class="list-group-item list-group-item-action text-light"><h5>Operador: { (movPatioTemp? movPatioTemp.operadorNombre: "") }</h5></li>
                  <li href="" class="list-group-item list-group-item-action text-light"><h5>Contacto:</h5>
                    <span>
                       { movPatioTemp && Number(movPatioTemp.operadorContacto) && !Number(softphone.getLlamarClic())?
                        <button type="button" className="btn btn-success btn-sm"
                          onClick={()=>{
                            if(movPatioTemp && !Number(softphone.getLlamarClic())){
                              softphone.setLlamarClic(movPatioTemp.operadorContacto);
                            }
                          }}
                        >
                          <i style={{fontSize: "20px"}}
                            className="fa fa-volume-control-phone text-primary"
                            data-toggle="tooltip" data-placement="top" title={"Realizar llamada a "+ movPatioTemp.operadorContacto}                        
                          ></i>
                          <label className="ml-3">llamar a {movPatioTemp? movPatioTemp.operadorContacto : ""}</label>
                        </button>
                          : null
                        }                        
                    </span>
                  </li>
                </div>
              </div>;
            }else{
              const operadorTemp= movimientoTemp.operador;
              mensajeTemp= 
                <ul class="list-group text-light">
                  <li class="list-group-item list-group-item-actio text-lightn"><h5>LT: {movimientoTemp[idMovimiento].transportista.nombre}</h5></li>
                  <li class="list-group-item list-group-item-action text-light"><h5>Placa: {movimientoTemp[idMovimiento].placa}</h5></li>
                  <li class="list-group-item list-group-item-action text-light"><h5>Operador: { (operadorTemp? (operadorTemp.nombre+" "+operadorTemp.paterno+" "+operadorTemp.materno): "") }</h5></li>
                  <li class="list-group-item list-group-item-action text-light"><h5>Contacto:</h5>
                    <span>
                      <span>
                        { Number(operadorTemp.medioComunicacion) && !Number(softphone.getLlamarClic())?
                          <i
                            className="fa fa-volume-control-phone text-primary btn"
                            data-toggle="tooltip" data-placement="top" title={"Realizar llamada a "+ operadorTemp.medioComunicacion}
                            style={{ fontSize: "24px"}}
                            onClick={()=>{
                              if(operadorTemp && !Number(softphone.getLlamarClic())){
                                softphone.setLlamarClic(operadorTemp.medioComunicacion);
                              }
                            }}
                          ></i>
                          : null
                        }
                        </span>
                        <span className="ml-3">
                          <h5>{operadorTemp? operadorTemp.medioComunicacion : ""}</h5>
                        </span>                      
                    </span>
                  </li>
                </ul>;
            }
            //Mensaje dinamico
            // NotificationManager.error( mensajeTemp,
            //   `${cat[idPlataforma][mensaje.data.idAlertaTipo]?.alertaTipo?.nombre}, Movimiento: ${idMovimiento}`,
            //     cat[idPlataforma][mensaje.data.idAlertaTipo].notificacionTimeSeg*1000
            // );
          }
        } else {
          if (!alertasMovimientos[idMovimiento]["fa-bell"]) {
            alertasMovimientos[idMovimiento]["fa-bell"] = [];
          }
          alertasMovimientos[idMovimiento]["fa-bell"].push({
            alert: mensaje.data,
            color: "blue",
            tipo: "Desconocido",
          });
          if (!alertCat["Desconocido"]) {
            Object.assign(alertCat, { Desconocido: [] });
          }
          alertCat["Desconocido"].push({
            alert: mensaje.data,
            color: "blue",
            logo: "fa-bell",
            tiempo: "00:00:00",
          });
          hacerEvento("Desconocido");
        }
      } else {
        if (!alertasMovimientos[idMovimiento]["fa-bell"]) {
          alertasMovimientos[idMovimiento]["fa-bell"] = [];
        }
        alertasMovimientos[idMovimiento]["fa-bell"].push({
          alert: mensaje.data,
          color: "blue",
          tipo: "Desconocido",
        });
        if (!alertCat["Desconocido"]){
          alertCat["Desconocido"] = [];
        }
        alertCat["Desconocido"].push({
          alert: mensaje.data,
          color: "blue",
          logo: "fa-bell",
          tiempo: "00:00:00",
        });
        

        hacerEvento("Desconocido");
      }
    } else if (mensaje.accion === "put") {
      if (!cat[idPlataforma][mensaje.data.idAlertaTipo]) {
        if (alertasMovimientos[idMovimiento]) {
          if (alertasMovimientos[idMovimiento]["fa-bell"]) {
            for (let alerta of alertasMovimientos[idMovimiento]["fa-bell"]) {
              if (alerta.alert.id === mensaje.data.id) {
                alerta.alert = mensaje.data;
              }
            }
          }
        }

        for (let alerta of alertCat["Desconocido"]) {
          if (alerta.alert.id === mensaje.data.id) {
            alerta.alert = mensaje.data;
          }
        }
      } else {
        if(alertasMovimientos[idMovimiento]
          &&
          alertasMovimientos[idMovimiento][cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.logo]
        ){
          for (let alerta of alertasMovimientos[idMovimiento][
            cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.logo
          ]) {
            if (alerta.alert.id === mensaje.data.id) {
              alerta.alert = mensaje.data;
            }
          }
        }

        for (let alerta of alertCat[
          cat[idPlataforma][mensaje.data.idAlertaTipo].alertaCategoria.nombre
        ]) {
          if (alerta.alert.id === mensaje.data.id) {
            alerta.alert = mensaje.data;
          }
        }
      }
    }
    
    if (tipo === "TERRESTRE"){
      updateT(alertasMovimientos, alertCat);
    } else {
      update(alertasMovimientos, alertCat);
    }
    
  };

  const udate = (mensaje) => {
    mensaje = mensaje ? JSON.parse(mensaje) : mensaje;

    //document.getElementById("alerta12").play();    
    /**
     * "sistema"
     */
    if (mensaje.vista == "sistema") {
      if (mensaje.data) {
        if (mensaje.data.refresh) {
          let randomN =
            Math.floor(Math.random() * (mensaje.data.refresh - 10)) + 10;
          setTimeout(function () {
            window.location.reload();
          }, randomN * 1000);
          // NotificationManager.warning(
          //   mensaje.mensaje + randomN + " Seg.",
          //   "Sistemas"
          // );
        }
      }
    }
    
    /**
     *  Alertas Navieras
     */
    if (mensaje.vista == "navierasAlertas" && navieras) {
      actualizaAlertas(
        alertasNCat,
        elementosN,
        mensaje,
        "NAVIERA"
      );
    }

    /**
     *  Alertas Terrestres
     */
    if (mensaje.vista == "terrestresAlertas" && terrestres) {
      
      actualizaAlertas(
        alertasTCat,
        elementosT,
        mensaje,
        "TERRESTRE"
      );
    }

    /**
     *  Acumuladores Etas
     */
    if (mensaje.vista == "movimientoAcumuladores" && navieras) {
      if (mensaje.accion == "post") {
        let acumuladoresTemp = etasN;
        if (!acumuladoresTemp[mensaje.data.idMovimiento]) {
          acumuladoresTemp[mensaje.data.idMovimiento] = {};
        }
        acumuladoresTemp[mensaje.data.idMovimiento][
          mensaje.data.idAcumulador.id
        ] = mensaje.data;
        if (updateEtas !== "") {
          updateEtas(acumuladoresTemp);
        }
      }
      postOrigin = false;
    }
  };
    
  return <></>;
}
export default PanelMensajeria;
