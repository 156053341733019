import React, { useEffect, useState } from 'react';
import Crud_Catalogos from '../../herramientas/Crud_Catalogos';
import './GpsInfo.css';
import { timeToDateFill } from "../../herramientas/DateFormat";
import { CircularProgress } from '@mui/material';
import ModalLay from '../../components/ModalLay/ModalLay';
import { MovimientoGpsEvidenciaModal } from '../../components/MovimientoGpsEvidenciaModal/MovimientoGpsEvidenciaModal';

const ValidacionMovimientoGps = ({ accion, idusuario, idmovimiento, idGps, registro, refrescar, reset, cerrarModal }) => {

    const[informacionGps, setInformacionGps] = useState({});
    const [mostrarMensaje, setMostrarMensaje] = useState(false);
    const [loading, setLoading] = useState(true);

    const[usuariosLog, setUsuarioslog] = useState({
      idBitacoraMonitoreo: 5,
      idUsuario: idusuario,
      idMovimiento: idmovimiento,
      accionFecha: timeToDateFill(new Date().getTime()),
      descripcion: "Validacion de GPS: " + registro.gps,
      idPlataformaTipo: registro.idPlataformaTipo,
    });

    useEffect(() => {
        //console.log("cerrarModal", cerrarModal);
        //console.log("idusuario", idusuario);
        //console.log("idmovimiento", idmovimiento);
        //console.log("registro", registro);
        //console.log("accion", accion);
        //console.log("idGps", idGps);
        //console.log("plataforma id gpsgate", registro.plataformaIdGpsgate );
        //console.log(usuariosLog);
        crud("get", "", "", "gpsestatus/plataforma/"+registro.plataformaIdGpsgate+"/gps/"+idGps, "apiGpsgate");
    }, [idGps]);

    useEffect(() => {
      //console.log(informacionGps.length === undefined)
      if (informacionGps.length === undefined) {
        const timer = setTimeout(() => {
          setMostrarMensaje(true);     
          setLoading(false);     
        }, 2000); // Retraso de 2 segundos  
        return () => clearTimeout(timer); // Limpieza del timer al desmontar
      } else {
        setMostrarMensaje(false);
      }
    }, [informacionGps]);


    const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {    
      //console.log(catalogo)  
      return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            //console.log(returnVal)
        switch (metodo) {
                case "get": 
                if(stateVar === "apiGpsgate" && returnVal) {
                  //console.log(returnVal);              
                  setInformacionGps(returnVal);
                  setLoading(false);
                }                
                break; 
                case "put":
                if(stateVar === "usuarioValidaGps" && returnVal) {
                  //console.log("usuarioValidaGps" + returnVal);   
                  crud("post", usuariosLog , "", "usuariosLog", "usuariosLog"); 
                  refrescar();
                  cerrarModal();
                } 
                break;          
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
      } 

    const isEmpty = (obj) => Object.keys(obj).length === 0;

    // Función para manejar la validación de la información
    const validarGps = (idmovimiento) => {
      //crud("put", {"idmovimiento": idmovimiento} , "", "navieraMovimientos/validarGps/" + idmovimiento, "validarGps"); 
      crud("put", {"idmovimiento": idmovimiento} , "", "movimientosPatio/usuarioValidaGps/" + idusuario + "/" + idmovimiento, "usuarioValidaGps");                     
     
    };

    // Función para manejar el reseteo de la información
    const resetGps = (idmovimiento) => {
      reset(idmovimiento);
      cerrarModal();
    };
    
  return (
    <>
      {!isEmpty(informacionGps) ? (
        <div className="gps-info-container">
          <h1 className="gps-info-title">Información del GPS desde GpsGate</h1>
          <table className="gps-info-table">
            <tbody>  
              <tr>
                <td>Id Movimiento:</td>   
                <td><strong>{idmovimiento}</strong></td>            
              </tr>   
              <tr>
                <td>Gps:</td>   
                <td><strong>{registro.gps}</strong></td>            
              </tr>  
              <tr>
                <th><strong>Latitud:</strong></th>
                <td>{informacionGps.latitud}</td>
              </tr>
              <tr>
                <th><strong>Longitud:</strong></th>
                <td>{informacionGps.longitud}</td>
              </tr>
              <tr>
                <th><strong>Dirección:</strong></th>
                <td>{informacionGps.direccion}</td>
              </tr>
              <tr>
                <th><strong>Nivel de Batería:</strong></th>
                <td>{informacionGps.nivel_bateria}%</td>
              </tr>
              <tr>
                <th><strong>Último Reporte:</strong></th>
                <td>{informacionGps.ultimo_reporte}</td>              
              </tr>
              <tr>
                <td><strong>TRACTO</strong></td>
                <td></td>
              </tr>
              <tr>
                <th><strong>Modelo Tractor:</strong></th>
                <td>{registro.tractorModelo}</td>
              </tr>
              <tr>
                <th><strong>Marca Tractor:</strong></th>
                <td>{registro.tractorMarca}</td>
              </tr>
              <tr>
                <th><strong>Color Tractor:</strong></th>
                <td>{registro.tractorColor}</td>
              </tr>
              <tr>
                <th><strong>Placa Tractor:</strong></th>
                <td>{registro.tractorPlaca}</td>
              </tr>
              <tr>
                <th><strong>Tipo Plataforma:</strong></th>
                <td>{registro.idPlataformaTipo === 1 ? "Naviera": registro.idPlataformaTipo === 2 ? "Terrestre" : "Terrestre naviera" }</td>
              </tr>
              <tr>
                <th><strong>Economico (tractor):</strong></th>
                <td>{registro.tractorPlaca}</td>
              </tr>              
              <tr>
                <td><strong>OPERADOR</strong></td>
                <td></td>
              </tr>
              <tr>
                <th><strong>Nombre Operador:</strong></th>
                <td>{registro.operadorNombre}</td>
              </tr>
              <tr>
                <th><strong>Medio Contacto:</strong></th>
                <td>{registro.operadorContacto}</td>
              </tr>
              <tr>
                <th><strong>Licencia:</strong></th>
                <td>{registro.operadorLicencia}</td>
              </tr>
              <tr>
                <td><strong>GPS (requerido))</strong></td>
                <td></td>
              </tr>
              <tr>
                <th><strong>Placa Remolque:</strong></th>
                <td>{registro.remolquePlaca}</td>
              </tr>
              <tr>
                <th><strong>Economico (remolque):</strong></th>
                <td>{registro.economicor }</td>
              </tr>
              <tr>
                <th><strong>Observaciones:</strong></th>
                <td>{registro.observaciones}</td>
              </tr>
              <tr>
                <th><strong>Evidencias:</strong></th>
                <td><ModalLay
                  tamanio={1200}
                  title="Evidencias colocación"
                  name={
                    <span
                      style={{
                        color: '#63e67b',
                        'font-size': '200%',
                      }}
                    >
                      <i className="fa fa-picture-o" />
                    </span>
                  }
                  formulario={MovimientoGpsEvidenciaModal}
                  datosForm={{ movimiento_id: registro.idMovimiento, gps: registro.gps }}
                /></td>
              </tr>
            </tbody>
          </table>
          <div className="button-group">
            <button className="validate-button" onClick={() => validarGps(idmovimiento)}>Validar Información</button>
            <button className="reset-button" onClick={() => resetGps(idmovimiento)}>Resetear Movimiento</button>
          </div>
        </div>) : loading ? (
          <div className="loading-container">
            <h2 className="gps-info-title"><CircularProgress />
            <p>Cargando información del GPS...</p></h2>          
        </div>
        ) : mostrarMensaje && (<h2 className="gps-info-title">No se pudo recuperar información del GPS desde GpsGate</h2>)}        
    </>
  )
}

export default ValidacionMovimientoGps;