import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ReaccionLink = ({
  plataformaTipo,
  getMovimiento,
  gpsEstatusNavieros,
  gpsEstatusTerrestres,
  unidad,
  entregas = [],
  user,
}) => {
  const isNaviera = plataformaTipo === 'Naviera';
  const gpsStatus = isNaviera ? gpsEstatusNavieros : gpsEstatusTerrestres;
  const movimientoGps = getMovimiento?.movimientoGps?.[0]?.gps?.id;

  const [gpsReciente, setGpsReciente] = useState({ latitud: 0.0, longitud: 0.0 });

  const fetchGpsData = async () => {
    try {
      const response = await fetch(
        `gpsestatus/plataforma/${getMovimiento?.movimientoGps[0]?.gps?.idGgApp}/gps/${getMovimiento?.movimientoGps[0].gps?.idGg}`
      );
      if (!response.ok) {
        throw new Error('Error al obtener datos del GPS');
      }
      return await response.json();
    } catch (error) {
      console.error('Error al obtener las coordenadas del GPS:', error);
      return {
        latitud: movimientoGps ? gpsStatus[movimientoGps]?.latitud : '',
        longitud: movimientoGps ? gpsStatus[movimientoGps]?.longitud : '',
      };
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();
    const data = await fetchGpsData();
    setGpsReciente({ latitud: data.latitud, longitud: data.longitud });
    const params = new URLSearchParams(
      isNaviera
        ? {
            latitud: data.latitud,
            longitud: data.longitud,
            marca: '',
            placa: '',
            modelo: '',
            color: '',
            economico: getMovimiento?.eco ?? '',
            caja: '',
            operador: getMovimiento?.idTransportista?.contactoNombre ?? '',
            medio: getMovimiento?.idTransportista?.telefono1 ?? '',
            origen:
              getMovimiento?.idMovimientoTipo === 1 || getMovimiento?.idMovimientoTipo === 3
                ? getMovimiento?.idCorredor?.nombre
                : getMovimiento?.idCliente?.domicilio,
            destino:
              getMovimiento?.idMovimientoTipo === 2 || getMovimiento?.idMovimientoTipo === 4
                ? getMovimiento?.idCorredor?.nombre
                : getMovimiento?.idCliente?.domicilio,
            transportista: getMovimiento?.idTransportista?.nombre ?? '',
            plataforma: getMovimiento?.idPlataforma?.nombre ?? '',
            movimiento_externo_id: getMovimiento?.id ?? '',
            estatus_carga: '',
            usuario_externo_id: user.id,
            usuario_externo_nombre: [user.persona.nombre, user.persona.aPaterno, user.persona.aMaterno].join(' '),
            sistema_id: 2,
          }
        : {
            latitud: data.latitud,
            longitud: data.longitud,
            marca: unidad?.tractoMarca ?? '',
            placa: unidad?.tractoPlaca ?? '',
            modelo: unidad?.tractoModelo ?? '',
            color: unidad?.tractoColor ?? '',
            economico: getMovimiento?.economico ?? '',
            caja: getMovimiento?.caja ?? '',
            operador: getMovimiento?.operador
              ? `${getMovimiento.operador.nombre} ${getMovimiento.operador.paterno} ${getMovimiento.operador.materno}`
              : '',
            medio: getMovimiento?.operador?.medioComunicacion ?? '',
            origen: getMovimiento?.origen ?? '',
            destino: entregas.find((entrega) => entrega.estatus)?.direccion ?? '',
            transportista: getMovimiento?.transportista?.nombre ?? '',
            plataforma: getMovimiento?.plataforma?.nombre ?? '',
            movimiento_externo_id: getMovimiento?.id ?? '',
            estatus_carga: getMovimiento?.deRegreso ? 'Con carga' : 'Sin carga',
            usuario_externo_id: user.id,
            usuario_externo_nombre: [user.persona.nombre, user.persona.aPaterno, user.persona.aMaterno].join(' '),
            sistema_id: 3,
          }
    ).toString();
    window.open(`https://reaccion.skyangel.com.mx/reaccion?${params}`, '_blank');
  };

  return (
    <a href="#" className="mt-n1" onClick={handleClick}>
      <label style={{ color: 'red', fontSize: '150%' }}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </label>
    </a>
  );
};

export default ReaccionLink;
