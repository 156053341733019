import React, { useEffect, useState } from 'react';
import Crud_Catalogos from '../../herramientas/Crud_Catalogos';
import LoadStore from '../../store/LoadStore';
import SiteWrapper from '../../SiteWrapper.react';
import { Grid } from 'tabler-react';
import SplitterLayout from 'react-splitter-layout';
import AlertasUnidades from '../../components/AlertasUnidades';
import AlertasUnidadesSinRuta from '../../components/AlertasUnidadesSinRuta';
import AlertasUnidadesSinRutaTerNav from '../../components/AlertasUnidadesSinRutaTerNav';
import UltimaActividadUsuarios from '../../components/UltimaActividadUsuarios';
import AlertasPrioritarias from '../../components/AlertasPrioritarias';

const AlertasUnidadesEnRutaPage = () => {

  const [tab, setTab]= useState(1);

    useEffect(() => {
        crud("get", "", "", "perfilesFuncionalidades", "columnas"); 
    }, []);


    const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
        return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": 
                if(stateVar === "columnas") {
                       
                }  
                break;                       
            default:
                break;
            }
        }).catch(err => { 
            if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
        });
    } 

  return (
    <>         
      {<LoadStore/>}
      <SiteWrapper>
        <Grid.Col sm={12} lg={12}>
          <div style={{ height: "100vh", "font-size": "smaller"}}>
            <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
              <div div className= "mt-1">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(1)}>Alertas Unidades en Ruta</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=> setTab(2) }>Alertas Unidades sin Ruta Terrestres</a>
                    </li> 
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 3 ? " active":"")} onClick={()=> setTab(3) }>Alertas Unidades sin Ruta Navieras</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 4 ? " active":"")} onClick={()=> setTab(4) }>Ultima Actividad de Usuarios</a>
                    </li>  
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 5 ? " active":"")} onClick={()=> setTab(5) }>Tiempo de Atención de Alertas Prioritarias</a>
                    </li>        
                  </ul>
                  <div hidden={tab !== 1}>
                    <AlertasUnidades/>   
                  </div>
                  <div hidden={tab !== 2}>
                    <AlertasUnidadesSinRuta/>   
                  </div>
                  <div hidden={tab !== 3}>
                    <AlertasUnidadesSinRutaTerNav/>   
                  </div>
                  <div hidden={tab !== 4}>
                    <UltimaActividadUsuarios/>   
                  </div>
                  <div hidden={tab !== 5}>
                    <AlertasPrioritarias/>
                  </div>
                </div>              
              </div>                           
            </SplitterLayout>
          </div>
        </Grid.Col>
      </SiteWrapper>     
    </>
  )
}

export default AlertasUnidadesEnRutaPage;