import React, { useEffect, useContext, useState } from "react";
import Stomp from "stompjs";
//https://b-1e48808f-d687-4faf-884d-43d58b310371-2.mq.us-east-1.amazonaws.com
/*
const url =  "wss://b-1e48808f-d687-4faf-884d-43d58b310371-2.mq.us-east-1.amazonaws.com:61619";
const user = "sistemas";
const pass = "f4By4XqB8kYt";
*/
const url =  "wss://b-1e48808f-d687-4faf-884d-43d58b310371-2.mq.us-east-1.amazonaws.com:61619";
const user = "telefonia";
const pass = "W4B7Sd9OlFb1";

let client = Stomp.client(url);

const PbxMensageria =({ topics, obtenerMensaje })=> {
  const [mensajeEntrante, setMensajeEntrante] = useState(null);

  useEffect(() => {
    if(topics.length){console.log("topics: ", topics);
      console.log("conectar al websocket: ");
      conectar();
    }
  }, [topics]);

  const conectar = () => {
    client = Stomp.client(url);
    client.heartbeat = { incoming: 20000, outgoing: 20000, };
    client.debug = () => {};
    client.reconnect_delay = 5000;
    client.connect(
      user,
      pass,
      (frame) => {
        //Conect callback
        if (topics && topics.length) {
          for (let t of topics) {
            client.subscribe(t, (message) => {
              if (message.body.includes("{")) {
                setMensajeEntrante(message.body);
              }
            });
          }
        }
      },
      (err) => {
        //error useCallback
        console.log(err);
        if (typeof err === "string" && err.toUpperCase().includes("LOST CONNECTION")) {
          setTimeout(() => {
            conectar();
          }, 5000);
          //alert(err);
        }
      }
    );
  }

  useEffect(() => {
    if(mensajeEntrante){
      //console.log("mensajeEntrante: ", mensajeEntrante);
      obtenerMensaje(JSON.parse(mensajeEntrante));
    }
  }, [mensajeEntrante]);

/*
  const sendMessage = (topic, msg, data) => {
    client.send(topic, {}, msg);
  };
  */

  return <></>;
}
export default PbxMensageria;
