import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";

const enviar = (accion, payload, setData, setLoading, setError) => {
	let resultados = [];
	if (payload === "") {
		setData([]);
		setLoading(false);
		setError(false);
		return;
	}
	switch (accion) {
		case "Inicio":
			setLoading(true);
			setError(false);
			break;
		case "Error":
			setLoading(false);
			setError(true);
			break;
		case "Plataformas":
			payload.map((plataforma, index) =>
				resultados.push({
					value: plataforma.id_plataforma,
					label: plataforma.nombre
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Transportistas":
			payload.map((transportista, index) =>
				resultados.push({
					value: transportista.id,
					label: transportista.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Resultados":
			payload.map((resultado, index) =>
				resultados.push({
					value: resultado.nombre,
					count: resultado.count,
					tipo: resultado.tipo,
					id: resultado.id,
					semana: resultado.semana,
					plataforma: resultado.plataforma,
					transportista: resultado.transportista,
					exportaciones: "Exportation:"+resultado.exportaciones,
					importaciones: "Importation:"+resultado.importaciones,
					conAdmin:"Con Admin:"+resultado.con_administracion,
					sinAdmin:"Sin Admin:"+resultado.sin_administracion,
					sinMod:"Sin Modalidad:"+resultado.sin_modalidad,
					bajoProtocolo:"Bajo Protocolo:"+resultado.bajo_protocolo,
					bajoProtocoloMabe:"Bajo Protocolo Mabe:"+resultado.bajo_protocolo_mabe,



					cdr_monterrey: "Cdr Monterrey" + resultado.cdr_monterrey,
					cdr_guadalajara: "Cdr Guadalajara" + resultado.cdr_guadalajara,
					cdr_huehuetoca: "Cdr Huehuetoca" + resultado.cdr_huehuetoca,
					cdr_amerisa_queretaro: "Cdr Amerisa Queretaro" + resultado.cdr_amerisa_queretaro,
					planta_slp: "Planta SLP" + resultado.planta_slp,
					planta_mcm_slp: "Planta MCM SLP" + resultado.planta_mcm_slp,
					planta_celaya: "Planta Celaya" + resultado.planta_celaya,
					planta_saltillo: "Planta Saltillo" + resultado.planta_saltillo,
					planta_queretaro: "Planta Queretaro" + resultado.planta_queretaro,
					planta_queretato_typ: "Planta Queretaro TYP" + resultado.planta_queretato_typ,
					planta_queretato_mpt: "Planta Queretaro MPT" + resultado.planta_queretato_mpt,
					faber_slp: "Faber SLP" + resultado.faber_slp,
					koblenz_cuahutitlan: "Koblenz Cuahutitlan" + resultado.koblenz_cuahutitlan,
					puerto_manzanillo: "Puerto Manzanillo" + resultado.puerto_manzanillo,
					puerto: "Puerto" + resultado.puerto,
					patio_linea_transportista: "Patio Linea Transportista" + resultado.patio_linea_transportista,
					cliente_externo: "Cliente Externo" + resultado.cliente_externo,
					cdr_kaisen_queretaro: "Cdr Kaisen Queretaro" + resultado.cdr_kaisen_queretaro,


				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Bloques":
			payload.filter(
				bloque => bloque.nombre.includes("Contador ") && bloque.nombre.includes("Terrestre")
			).map((bloque, index) =>
				resultados.push({
					value: bloque.id,
					label: bloque.nombre
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Funcionalidades":
			payload.map((resultado, index) =>
				resultados.push({
					value: resultado.id,
					label: resultado.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		default:
			throw new Error();
	}
};

const FetchData = (url, opcion, setData, setLoading, setError, semana) => {
	enviar("Inicio", null, setData, setLoading, setError);

	Crud_Catalogos(url, "dashboardTerrestre", "get", "", "", "", opcion, "", [])
		.then((returnVal) => {
			for (let i = 0; i < returnVal.length; i++) {
				returnVal[i].semana = parseInt(semana);
			}
			enviar(opcion, returnVal, setData, setLoading, setError);
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
			} else {
				console.log("Error desconocido ...." + err);
			}
			enviar("Error", null, setData, setLoading, setError);
		});
};

export default FetchData;
